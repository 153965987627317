import { Dispatch, SetStateAction, useContext } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import Button from "src/components/Button";
import styles from "./styles.module.scss";
import { NumberOfGuestsByCategory } from "src/graphql/queries";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { CreateGuestCategory } from "src/graphql/mutations";
import { createGuestCategory } from "src/types/api";
import { useNavigate } from "react-router-dom";

interface Values {
  categoryName: string;
}

const AddCategoryFormSchema = Yup.object().shape({
  categoryName: Yup.string().required("Category Name is required"),
});

interface AddCategoryProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddCategory = ({ setOpen }: AddCategoryProps) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const [createGuestCategory, { loading }] = useMutation<createGuestCategory>(
    CreateGuestCategory,
    {
      onCompleted() {
        setOpen(false);
        toast.success(
          <p className="toast">You have successfully created a new category.</p>
        );
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  return (
    <div className={`${styles["addCategoryModal--content"]}`}>
      <h1> Add Category </h1>
      <Formik
        initialValues={{
          categoryName: "",
        }}
        validationSchema={AddCategoryFormSchema}
        onSubmit={(values, actions) => {
          createGuestCategory({
            variables: {
              event_id: eventId,
              name: values.categoryName,
            },
            refetchQueries: [
              {
                query: NumberOfGuestsByCategory,
                variables: { eventId: eventId },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["addCategoryModal--content-form"]}>
            <div className={styles["addCategoryModal--content-form_container"]}>
              <Input
                type="text"
                name="categoryName"
                label="Category Name"
                placeholder="Enter Category Name"
              />
            </div>
            <div className={styles["addCategoryModal--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Category"
                  disabled={props.values.categoryName === ""}
                  loader={loading}
                >
                  Add
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCategory;
