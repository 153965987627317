import styles from "./appreciateGift.module.scss";
import { Formik, Form, FormikProps } from "formik";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button";
import * as Yup from "yup";
import Icon from "src/components/Icon";
import React, { useContext } from "react";
// import { AppreciateGiftType } from "src/pages/gifts";
import { AppContext, ValueProps } from "src/context";
// import { appreciateGiftVariables } from "src/types/api";

export interface Values {
  note?: string;
}

const AppreciateGiftSchema = Yup.object().shape({
  note: Yup.string(),
});

export interface AppreciateGiftsProps {
  variables: {
    eventId: number | null | undefined;
    itemId: number | undefined;
    appreciation: string;
  };
}

interface AppreciateGiftProps {
  setToggleModal: (toggleModal: boolean) => void;
  appreciateGift: (appreciateGift: AppreciateGiftsProps) => void;
  id?: number;
  loading: boolean;
}

const AppreciateGift: React.FC<AppreciateGiftProps> = ({
  setToggleModal,
  appreciateGift,
  loading,
  id,
}) => {
  const handleGoBack = () => {
    setToggleModal(false);
  };
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;

  return (
    <div className={styles["appreciateGift"]}>
      <div className={styles["appreciateGift-heading"]}>
        <button type="button" onClick={handleGoBack}>
          <Icon iconName="leftArrow" />
        </button>
        <h4>Appreciate Gift</h4>
        <p>Send a Thank you note</p>
      </div>
      <Formik
        initialValues={{
          note: "",
        }}
        validationSchema={AppreciateGiftSchema}
        onSubmit={(values, actions) => {
          appreciateGift({
            variables: {
              eventId: eventId,
              itemId: id,
              appreciation: values.note,
            },
          });

          actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["appreciateGift-form"]}>
            <div className={styles["appreciateGift-form-container"]}>
              <Input
                type="textarea"
                name="note"
                label="Note"
                placeholder="Enter a Thank You message"
              />
            </div>
            <div className={styles["appreciateGift-button-container"]}>
              <div className={styles["appreciateGift-button"]}>
                <Button
                  type="submit"
                  label="Send Note"
                  disabled={props.values.note === ""}
                  loader={loading}
                >
                  Send Note
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AppreciateGift;
