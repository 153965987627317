import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "src/layouts/dashboard";
import styles from "./myPlan.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Backdrop from "src/assets/images/bgImage.svg";
import { v4 as uuidv4 } from "uuid";
import Icon from "src/components/Icon";
import { useQuery } from "@apollo/client";
import { GetEventPlan } from "src/graphql/queries/event.queries";
import { getEventPlan, getEventPlanVariables } from "src/types/api";
import toast from "react-hot-toast";
import { AppContext, ValueProps } from "src/context";
import { useContext } from "react";
import { Spinner } from "src/components/Spinner";

const MyPlan = () => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { loading: loadingEventPlan, data: eventPlanData } = useQuery<
    getEventPlan,
    getEventPlanVariables
  >(GetEventPlan, {
    skip: !eventId,
    variables: {
      eventId: !eventId ? 0 : eventId,
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const planList = [
    {
      title: "Upload Invitation Card",
      sub: "Upload your invitation card or select from a wide range of templates",
      link: "/invitations?tab=invitationCard",
      done: eventPlanData?.getEventPlan.invitation_card,
    },

    {
      title: "Update Event Details",
      sub: "Fill in the event details",
      link: "/event-details?tab=generalInformation",
      done: eventPlanData?.getEventPlan.event_details,
    },

    {
      title: "Update Backstory",
      sub: "How did you meet your partner? Tell us",
      link: "/website?tab=backstory",
      done: eventPlanData?.getEventPlan.add_back_story,
    },

    {
      title: "Create Categories",
      sub: "Seperate your invite list into different categories",
      link: "/invitations?tab=categories",
      done: eventPlanData?.getEventPlan.categories,
    },

    {
      title: "Build your Invitation List",
      sub: "Build your Invitation list",
      link: "/invitations?tab=inviteList",
      done: eventPlanData?.getEventPlan.build_invite_list,
    },

    {
      title: "Add Wishlist!",
      sub: "Tell your guests what type of gifts you will like to see!",
      link: "/gifts?tab=wishlist",
      done: eventPlanData?.getEventPlan.add_wishlist,
    },

    {
      title: "Photobook Memories",
      sub: "Add pictures of you and your partner",
      link: "/website?tab=photobook",
      done: eventPlanData?.getEventPlan.upload_pictures,
    },

    {
      title: "Add Event Vendor",
      sub: "Add vendors to your event",
      link: "/event-vendors",
      done: eventPlanData?.getEventPlan.add_event_vendors,
    },

    {
      title: "Send Out Your Invites",
      sub: "Send out your Invite list to your guests",
      link: "/invitations?tab=inviteList",
      done: eventPlanData?.getEventPlan.send_out_invites,
    },
  ];
  return (
    <DashboardLayout>
      <div className={styles["my-plan__backdrop"]}>
        <LazyLoadImage
          src={Backdrop}
          placeholderSrc={Backdrop}
          alt="Backdrop"
          effect="blur"
          width="100%"
          height="100%"
          className={styles["my-plan__backdrop__image"]}
        />
      </div>
      <section className={styles["my-plan__body"]}>
        <div className={styles["my-plan__body__top"]}>
          <h1>My Wedding Plan</h1>
          <p>Take things one step at a time</p>
        </div>

        <div className={styles["my-plan__body__bottom"]}>
          <div className={styles["my-plan__body__bottom__deck"]}>
            {planList.map((item, idx) => (
              <div
                key={uuidv4()}
                className={`${styles["my-plan__body__bottom__deck__item"]} ${
                  idx === 0 && styles["my-plan__body__bottom__deck__item__done"]
                }`}
              >
                <div
                  className={styles["my-plan__body__bottom__deck__item__left"]}
                >
                  <div
                    className={`${
                      styles["my-plan__body__bottom__deck__item__tick"]
                    } ${
                      !loadingEventPlan &&
                      item.done &&
                      styles["my-plan__body__bottom__deck__item__tick__done"]
                    }`}
                  >
                    {loadingEventPlan ? <Spinner /> : <Icon iconName="tick2" />}
                  </div>
                  <div
                    onClick={() => navigate(item.link)}
                    className={`${
                      styles["my-plan__body__bottom__deck__item__details"]
                    } ${
                      !loadingEventPlan &&
                      item.done &&
                      styles["my-plan__body__bottom__deck__item__details__done"]
                    }`}
                  >
                    <h2>{item.title}</h2>
                    <p>{item.sub}</p>
                  </div>
                </div>
                <Link to={item.link}>
                  <Icon iconName="linkOut" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default MyPlan;
