import { gql } from "@apollo/client";

export const GetTeamMember = gql`
  query getTeamMember($eventId: Int!) {
    getTeamMember(event_id: $eventId) {
      event_id
      user_id
      user {
        first_name
        id
        phone_number
        last_name
        verified
        email
        image
      }
      admin
      id
      role
    }
  }
`;

export const CheckEventSlug = gql`
  query checkEventSlug($slug: String!) {
    checkEventSlug(slug: $slug)
  }
`;

export const GetEventPlan = gql`
  query getEventPlan($eventId: Int!) {
    getEventPlan(event_id: $eventId) {
      website_template
      send_out_invites
      upload_pictures
      invitation_card
      event_id
      event_details
      build_invite_list
      categories
      add_wishlist
      add_event_vendors
      add_back_story
    }
  }
`;
