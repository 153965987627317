import React, {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { getAccessToken } from "src/helper/storage";

interface AppContextProps {
  children: JSX.Element;
}

export interface ValueProps {
  acceptTeamToken: string | null;
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  eventId: number | null | undefined;
  setWalletCode: Dispatch<SetStateAction<string | null | undefined>>;
  setAcceptTeamToken: Dispatch<SetStateAction<string | null>>;
  walletCode: string | null | undefined;
  setEventId: Dispatch<SetStateAction<number | null | undefined>>;
}
export const AppContext = createContext<ValueProps | null>(null);

const AppContextProvider: React.FC<AppContextProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>("");
  const [acceptTeamToken, setAcceptTeamToken] = useState<string | null>(null);
  const [eventId, setEventId] = useState<number | null | undefined>(0);
  const [walletCode, setWalletCode] = useState<string | null | undefined>("");

  const getToken = () => {
    let data = getAccessToken();
    if (data) {
      setToken(data);
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("eventId");
    let wallet_code = localStorage.getItem("walletCode");
    if (data) {
      setEventId(JSON.parse(data));
    }
    if (wallet_code && wallet_code !== "undefined") {
      setWalletCode(JSON.parse(wallet_code));
    }
  }, []);

  useEffect(() => getToken, []);

  return (
    <AppContext.Provider
      value={{
        token,
        acceptTeamToken,
        setAcceptTeamToken,
        setToken,
        eventId,
        setEventId,
        setWalletCode,
        walletCode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
