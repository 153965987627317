import { Dispatch, SetStateAction, useContext, useState } from "react";
import Icon from "src/components/Icon";
import { SearchInput } from "src/components/Input";
import Button from "src/components/Button";
import styles from "./transfer.module.scss";
import toast from "react-hot-toast";

import {
  getBanksWithRave,
  getBanksWithRaveVariables,
  resolveAccountWithRave,
  transferToBank,
  transferToBankVariables,
} from "src/types/api";
import { AppContext, ValueProps } from "src/context";
import { useMutation, useQuery } from "@apollo/client";
import { GetEventWallets } from "src/graphql/queries/wallets.queries";
import { TransferToBank } from "src/graphql/mutations";
import { GetBanksWithRave, ResolveAccountWithRave } from "src/graphql/queries";
import { v4 as uuidv4 } from "uuid";
import * as Popover from "@radix-ui/react-popover";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useNavigate } from "react-router-dom";

interface TransferProps {
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

interface BankData {
  __typename: string | null;
  id: number | null;
  name: string | null;
  code: string | null;
}

const Transfer = ({ setOpen }: TransferProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<BankData>(
    {} as BankData
  );
  const [openBankList, setOpenBankList] = useState<boolean>(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();

  const { data: banks, loading: bankLoading } = useQuery<
    getBanksWithRave,
    getBanksWithRaveVariables
  >(GetBanksWithRave, {
    variables: {
      country: "NG",
    },
    fetchPolicy: "cache-and-network",
    onCompleted() {},
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const results = !!banks
    ? banks.getBanksWithRave?.filter((bank) =>
        bank?.name
          ?.toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      )
    : [];

  const onOptionClicked = (bank: BankData) => {
    setSelectedOption(bank);
    setOpenBankList(false);
  };

  const openChange = (open: boolean) => {
    setOpenBankList(open);
  };

  const {
    data: resolvedAccount,
    loading: resolvedAccountLoading,
    error: resolvedAccountError,
  } = useQuery<resolveAccountWithRave>(ResolveAccountWithRave, {
    variables: {
      accountNumber: accountNumber,
      accountBank: selectedOption.code,
    },
    skip: accountNumber.length !== 10,
    fetchPolicy: "cache-and-network",
    onCompleted() {},
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const [transferFn, { loading }] = useMutation<
    transferToBank,
    transferToBankVariables
  >(TransferToBank, {
    onCompleted(res) {
      setOpen(false);
      toast.success(<p>You transferred {res?.transferToBank}.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const walletId = contextValue?.walletCode;

  console.log(walletId);
  return (
    <div className={styles["transferModal--content"]}>
      <div className={styles["transferModal--content__top"]}>
        <h1>Transfer Funds</h1>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (walletId) {
            eventId &&
              transferFn({
                variables: {
                  input: {
                    currency: "ngn",
                    eventId: eventId,
                    walletId: walletId,
                    amount: Number(amount),
                    accountNumber: accountNumber,
                    accountBank: selectedOption.code,
                    narration: "",
                    bankCode: selectedOption.code,
                    reference: "",
                  },
                },
                refetchQueries: [
                  {
                    query: GetEventWallets,
                    variables: {
                      eventId,
                    },
                  },
                ],
              });
          } else {
            toast.error(<p>There's no wallet for this event.</p>);
          }
        }}
      >
        <div className={styles["transferModal--content-form_container"]}>
          <div
            className={styles["transferModal--content-form_container__select"]}
          >
            <label htmlFor="bankCode">Bank Name</label>

            <Popover.Root
              open={openBankList}
              onOpenChange={(open: boolean) => openChange(open)}
            >
              <Popover.Trigger>
                <div
                  className={
                    styles["transferModal--content-form_container__select-css"]
                  }
                >
                  {selectedOption.name || "Select Bank"}

                  {selectedOption.name ? "" : <Icon iconName="caretDouble" />}
                </div>
              </Popover.Trigger>
              <ScrollArea.Root>
                <ScrollArea.Viewport>
                  <Popover.Content
                    className={
                      styles[
                        "transferModal--content-form_container__select-css__content"
                      ]
                    }
                  >
                    <>
                      <div
                        className={
                          styles[
                            "transferModal--content-form_container__select-css__content__item"
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              "transferModal--content-form_container__searchbar"
                            ]
                          }
                        >
                          <SearchInput
                            icon={<Icon iconName="search" />}
                            name="Search Banks"
                            placeholder="Search Banks"
                            value={searchValue}
                            onChange={(e: {
                              target: { value: SetStateAction<string> };
                            }) => setSearchValue(e.target.value)}
                          />
                        </div>
                        {bankLoading ? (
                          <p
                            className={
                              styles[
                                "transferModal--content-form_container__tiny__2"
                              ]
                            }
                          >
                            Loading Banks...
                          </p>
                        ) : !bankLoading ? (
                          results.map((bank) => (
                            <div
                              className={
                                styles[
                                  "transferModal--content-form_container__banks"
                                ]
                              }
                              key={uuidv4()}
                              onClick={() => {
                                onOptionClicked(bank);
                              }}
                            >
                              {bank?.name}
                            </div>
                          ))
                        ) : (
                          <p
                            className={
                              styles[
                                "transferModal--content-form_container__tiny__2"
                              ]
                            }
                          >
                            No Banks!
                          </p>
                        )}
                      </div>
                    </>
                  </Popover.Content>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar orientation="vertical">
                  <ScrollArea.Thumb />
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner />
              </ScrollArea.Root>
            </Popover.Root>
          </div>

          <div
            className={styles["transferModal--content-form_container__group"]}
          >
            <p>Account Number</p>
            <input
              className={
                styles[
                  "transferModal--content-form_container__select-css__content__input"
                ]
              }
              type="number"
              name="accountNumber"
              value={accountNumber}
              placeholder="Enter Account Number"
              onChange={(e: any) => {
                // fetchDetails(e.target.value);
                setAccountNumber(e.target.value);
              }}
            />
            <p
              className={styles["transferModal--content-form_container__tiny"]}
            >
              {resolvedAccountLoading
                ? "Getting account..."
                : resolvedAccountError?.message
                ? "Couldn't get fetch account details"
                : resolvedAccount?.resolveAccountWithRave?.accountName === ""
                ? "Couldn't fetch account details"
                : resolvedAccount?.resolveAccountWithRave?.accountName}
            </p>
          </div>

          <div
            className={styles["transferModal--content-form_container__group"]}
          >
            <p>Amount</p>
            <input
              className={
                styles[
                  "transferModal--content-form_container__select-css__content__input"
                ]
              }
              type="number"
              name="amount"
              value={amount}
              placeholder="Enter Amount"
              onChange={(e: any) => {
                setAmount(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles["transferModal--content-btnContainer"]}>
          <div>
            <Button
              type="submit"
              label="Transfer"
              loader={loading}
              disabled={
                loading ||
                bankLoading ||
                accountNumber === "" ||
                amount === "" ||
                resolvedAccount?.resolveAccountWithRave?.accountName === ""
              }
            >
              Transfer
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Transfer;
