import styles from "./view.module.scss";
import { useState } from "react";
import Dialog from "../../../../components/Dialog";
import { getBackStory_getBackStory } from "src/types/api";
import EditBackStory from "../EditBackStory";

interface ViewBackStoryProps {
  story?: getBackStory_getBackStory | null;
  setToggleModal: (toggleModal: boolean) => void;
}

const ViewBackStory: React.FC<ViewBackStoryProps> = ({
  story,
  setToggleModal,
}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  return (
    <div className={styles["view"]}>
      <div className={styles["view-heading"]}>
        <h4>{!!story?.role ? `${story?.role}'s` : ""} Backstory</h4>
      </div>
      <div className={styles["view-body"]}>{story?.story ?? ""}</div>
      <div className={styles["view-button"]}>
        <Dialog
          open={openEdit}
          onOpenChange={setOpenEdit}
          trigger={
            <div
              onClick={() => {
                setOpenEdit(true);
              }}
              className={styles["view-trigger"]}
            >
              <span>Edit</span>
            </div>
          }
        >
          <EditBackStory
            story={story}
            setToggleModal={setOpenEdit}
            setToggleViewModal={setToggleModal}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default ViewBackStory;
