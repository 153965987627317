import moment from "moment/moment";

export const phoneRegExp =
  /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

export function getUtcOffset(date: Date) {
  return moment(date).add(moment(date).utcOffset(), "minutes").utc();
}

export function indexOfEarliestDate(arr: Date[]): number {
  if (arr.length === 0) {
    return -1;
  }

  var earliest = arr[0];
  var earliestIndex = 0;

  for (var i = 1; i < arr.length; i++) {
    if (moment(arr[i]).isBefore(earliest)) {
      earliestIndex = i;
      earliest = arr[i];
    }
  }

  return earliestIndex;
}

// a more concise version of the initial
export const indexOfEarliestDate2 = (arr: Date[]): number =>
  arr.reduce(
    (bestIndexSoFar, currentlyTestedValue, currentlyTestedIndex, array) =>
      moment(currentlyTestedValue).isBefore(array[bestIndexSoFar])
        ? currentlyTestedIndex
        : bestIndexSoFar,
    0
  );
