import { useQuery } from "@apollo/client";
import { useState, useEffect, useContext } from "react";
import {
  InvitationListByRSVP,
  NumberOfGuestsByCategory,
} from "src/graphql/queries";
import styles from "./coming.module.scss";
import Pagination from "src/components/Pagination";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import EmptyState from "../EmptyState";
import { v4 as uuidv4 } from "uuid";
import { SearchInput } from "src/components/Input";
import Icon from "src/components/Icon";
import toast from "react-hot-toast";
import { invitationListByRSVPStatus, noGuestPerCategory } from "src/types/api";
import Row from "./Row";
import { useNavigate } from "react-router-dom";

const Coming = () => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startPage, setStartPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { data: categories } = useQuery<noGuestPerCategory>(
    NumberOfGuestsByCategory,
    {
      variables: {
        eventId: eventId,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  useEffect(() => {
    if (totalPages < 6) {
      setPageSize(totalPages);
    } else {
      setPageSize(6);
    }
  }, [pageSize, totalPages, setPageSize]);

  const { loading: comingLoading, data: coming } =
    useQuery<invitationListByRSVPStatus>(InvitationListByRSVP, {
      variables: {
        eventId: eventId,
        rsvpStatus: "yes",
        page: page,
        pageSize: pageSize,
      },
      onCompleted(response) {
        setTotalPages(response?.invitationListByRSVPStatus.length);
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const RenderView = () => {
    if (comingLoading) {
      return (
        <div className={styles["coming__loading"]}>
          <InnerLoading />
        </div>
      );
    } else if (
      !!coming &&
      !!coming.invitationListByRSVPStatus &&
      coming?.invitationListByRSVPStatus.length
    ) {
      return (
        <>
          <div className={styles["search"]}>
            <SearchInput
              icon={<Icon iconName="search" />}
              name="Search names, emails etc."
              placeholder="Search names, emails etc."
            />
          </div>
          <main className={styles["coming__main"]}>
            <div className={styles["coming__table"]}>
              <div className={styles["coming__table-header"]}>
                <h4>NAME</h4>
                <h4>EMAIL</h4>
                <h4>PHONE NUMBER</h4>
                <h4>CATEGORY</h4>
              </div>
              <div className={styles["coming__table-list"]}>
                {coming.invitationListByRSVPStatus?.map((data, index) => (
                  <>
                    <Row
                      key={uuidv4()}
                      id={data?.id}
                      firstName={data?.first_name}
                      lastName={data?.last_name}
                      phoneNumber={data?.phone}
                      email={data?.email ?? ""}
                      category={data?.category?.name ?? ""}
                    />
                  </>
                ))}
              </div>
            </div>
          </main>
        </>
      );
    } else {
      return (
        <EmptyState categories={categories} page={page} pageSize={pageSize} />
      );
    }
  };

  return (
    <>
      <div className={styles["coming"]}>
        <RenderView />
        {/*!!coming && !!coming.invitationListByRSVPStatus && (
          <Pagination
            pageSize={pageSize}
            startPage={startPage}
            setPageSize={setPageSize}
            setStartPage={setStartPage}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
						)*/}
      </div>
    </>
  );
};

export default Coming;
