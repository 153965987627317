import DashboardLayout from "src/layouts/dashboard";
import styles from "./rsvp.module.scss";
import { useState } from "react";
import { RsvpTabType } from "src/utils/types";
import Tab from "src/components/Tab";
import Coming from "./Coming";
import NotComing from "./NotComing";
import Pending from "./Requests";

const tabItems = ["coming", "notComing", "pending"];

function Rsvp() {
  const [tabState, setTabState] = useState<RsvpTabType>("coming");

  const handleTab = (element: RsvpTabType) => {
    setTabState(element);
  };

  const tab: Record<RsvpTabType, JSX.Element> = {
    coming: <Coming />,
    notComing: <NotComing />,
    pending: <Pending />,
  };

  return (
    <DashboardLayout pageName="RSVP">
      <div className={styles["rsvp"]}>
        <div className={styles["rsvp__tabs"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div>
        <div className={styles["rsvp__main"]}>{tab[tabState]}</div>
      </div>
    </DashboardLayout>
  );
}

export default Rsvp;
