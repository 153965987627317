import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../src/styles/style.scss";
import { onError } from "@apollo/client/link/error";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { networkError as netError } from "./graphql/state/global/networkState";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import {
  getAccessToken,
  getRefreshToken,
  saveToStorage,
  removeAllFromStorage,
} from "./helper/storage";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Toaster } from "react-hot-toast";
import "react-lazy-load-image-component/src/effects/blur.css";
import { typeDefs } from "src/utils/graphql/typeDefs";
import axios from "axios";
import { updateStorageStatus } from "./graphql/state/auth/authActions";
import { accessToken } from "./graphql/state/auth/authState";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.SENTRY_ENV || "development",
    tracesSampleRate: 1.0,
  });
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API,
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from the inMemory cache
  const token = getAccessToken();

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      "x-user-token": token ? `${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const refreshTokens = () => {
  let token = "";
  const refreshToken = getRefreshToken() as string;

  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: `
        query refresh($refresh_token: String!) {
          refresh(refresh_token: $refresh_token) {
            refresh_token
            access_token
            access_expiry
          }
        }
        `,
      variables: {
        refresh_token: refreshToken,
      },
    },
  })
    .then((result) => {
      if (result?.data?.data?.refresh?.access_token) {
        const { refresh_token, access_token, access_expiry } =
          result?.data?.data?.refresh;
        const jwt = { refresh_token, access_token, access_expiry };

        saveToStorage("inawo", jwt);
        token = result.data.data.refresh.access_token;
      }
    })
    // .then(() => {
    //   window.location.reload();
    // })
    .catch((error) => {
      if (error?.message === "unauthenticated") {
        updateStorageStatus(false);
        removeAllFromStorage();
        accessToken("");
        window.location.replace("/login");
      }
    });

  return token;
};

const errorLink = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (let error of graphQLErrors) {
        switch (error.message) {
          case "unauthenticated":
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                "x-user-token": refreshTokens(),
              },
            });
            // retry the request, returning the new observable
            return forward(operation);
        }
      }
    }
    if (networkError) {
      if (networkError.message === "Failed to fetch") netError(true);
    }
  }
);

export const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  typeDefs,
  cache: new InMemoryCache({
    typePolicies: {
      Wallet: {
        fields: {
          id: {
            merge: false,
          },
        },
      },
    },
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <Toaster
          position="top-center"
          reverseOrder={false}
          containerStyle={{
            zIndex: "2147483647",
            isolation: "isolate",
            transformStyle: "preserve-3d",
            transform: "translateZ(1000px)",
          }}
          toastOptions={{
            duration: 3000,
            success: {
              style: {
                background: "#E9FBED",
                border: "0.5px dashed #1FAD3E",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: "12px 8px",
                fontFamily: "Nunito Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "19px",
                textAlign: "center",
                color: "#34544F",
                borderRadius: "0px",
                zIndex: "2147483647",
                isolation: "isolate",
                transformStyle: "preserve-3d",
                transform: "trasnslateZ(1000px)",
              },
            },
            error: {
              style: {
                background: "#FCE8E8",
                border: "0.5px dashed #B81414",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: "12px 8px",
                fontFamily: "Nunito Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "19px",
                textAlign: "center",
                color: "#8A0F0F",
                borderRadius: "0px",
                zIndex: "2147483647",
                isolation: "isolate",
                transformStyle: "preserve-3d",
                transform: "trasnslateZ(1000px)",
              },
            },
          }}
        />
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
