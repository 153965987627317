import Icon from "../Icon";
import styles from "./pagination.module.scss";
// import { v4 as uuidv4 } from "uuid";

interface PaginationProps {
  pageSize: number;
  totalPages: number;
  startPage?: number;
  setStartPage?: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

const Pagination: React.FC<PaginationProps> = ({
  pageSize,
  totalPages,
  startPage,
  setStartPage,
  setPageSize,
  page,
  setPage,
}) => {
  const handleNext = () => {
    const newPage = page + 1;
    setPage(newPage);
  };

  const handlePrevious = () => {
    if (page !== 1) {
      const newPage = page - 1;
      setPageSize(6);
      setPage(newPage);
    }
  };

  return (
    <div className={styles["pagination"]}>
      <button
        className={styles["pagination__direction"]}
        onClick={handlePrevious}
        disabled={page === 1}
      >
        <Icon iconName="previous" /> Previous
      </button>
      {/* {totalPages >= 1 &&
        [...Array(totalPages)].map((_, index) => (
          <button
            key={uuidv4()}
            className={`${styles["pagination__number"]} ${
              page === index + 1 && styles["pagination__number__active"]
            }`}
          >
            {index + 1}
          </button>
        ))} */}
      <button
        className={styles["pagination__direction"]}
        onClick={handleNext}
        disabled={pageSize < 6}
      >
        Next <Icon iconName="next" />
      </button>
    </div>
  );
};

export default Pagination;
