import { useState, useContext } from "react";
import styles from "./eventGallery.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import { GetAlbumDetails } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import {
  deleteAlbumMedia,
  deleteAlbumMediaVariables,
  getAlbumDetails,
} from "src/types/api";
import DialogModal from "src/components/Dialog";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EmptyState from "./EmptyState";
import AddPictures from "./AddPictures";
import Icon from "src/components/Icon";
import { DeleteAlbumMedia } from "src/graphql/mutations";
import { useNavigate } from "react-router-dom";

const EventGallery: React.FC = () => {
  const [pageSize] = useState(200);
  const [page] = useState(1);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { data: images, loading: getAlbumDetailsLoading } =
    useQuery<getAlbumDetails>(GetAlbumDetails, {
      variables: {
        eventId: eventId,
        page: page,
        pageSize: pageSize,
      },
      // onCompleted(response) {
      //   if (!!response?.getAlbumDetails && response?.getAlbumDetails.length) {
      //     setTotalPages(
      //       Math.floor(response?.getAlbumDetails.length / pageSize)
      //     );
      //   }
      // },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const [deleteAlbumMediaFn] = useMutation<
    deleteAlbumMedia,
    deleteAlbumMediaVariables
  >(DeleteAlbumMedia, {
    onCompleted() {
      toast.success(<p className="toast">Successfully deleted!</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["eventGallery"]}>
      {getAlbumDetailsLoading ? (
        <div className={styles["eventGallery__loading"]}>
          <InnerLoading />
        </div>
      ) : !!images?.getAlbumDetails && images?.getAlbumDetails.length > 0 ? (
        <>
          <div className={styles["eventGallery__trigger__group"]}>
            <div>
              <DialogModal
                open={openAdd}
                onOpenChange={setOpenAdd}
                trigger={
                  <div
                    onClick={() => setOpenAdd(true)}
                    className={styles["eventGallery__trigger"]}
                  >
                    <span>Add Pictures</span>
                  </div>
                }
              >
                <AddPictures
                  setToggleModal={setOpenAdd}
                  page={page}
                  pageSize={pageSize}
                />
              </DialogModal>
            </div>
          </div>

          <div className={styles["eventGallery__container"]}>
            {images?.getAlbumDetails?.map((element, index) => {
              return (
                !!element?.url && (
                  <div
                    className={styles["eventGallery__card__container"]}
                    key={uuidv4()}
                  >
                    <div
                      className={styles["eventGallery__card__delete"]}
                      onClick={() =>
                        eventId &&
                        deleteAlbumMediaFn({
                          variables: {
                            eventId: eventId,
                            mediaId: element.id,
                          },
                          refetchQueries: [
                            {
                              query: GetAlbumDetails,
                              variables: {
                                eventId: eventId,
                                page: page,
                                pageSize: pageSize,
                              },
                            },
                          ],
                        })
                      }
                    >
                      <Icon iconName="deleteFilled" />
                    </div>

                    <DialogModal
                      key={uuidv4()}
                      trigger={
                        <div className={styles["eventGallery__card"]}>
                          <LazyLoadImage
                            src={element?.url}
                            placeholderSrc={element?.url}
                            alt={`event-${index + 1}`}
                            effect="blur"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      }
                    >
                      <div className={styles["website__carousel"]}>
                        <div className={styles["website__carousel-image"]}>
                          <LazyLoadImage
                            src={element?.url}
                            placeholderSrc={element?.url}
                            alt={`img from cloudinary: ${element?.url}`}
                            effect="blur"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    </DialogModal>
                  </div>
                )
              );
            })}
          </div>
        </>
      ) : (
        <div className={styles["eventGallery__add-pictures-container"]}>
          <div className={styles["eventGallery__add-pictures"]}>
            <EmptyState page={page} pageSize={pageSize} />
          </div>
        </div>
      )}
      {/* <Pagination
        pageSize={pageSize}
        startPage={startPage}
        setPageSize={setPageSize}
        setStartPage={setStartPage}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
      /> */}
    </div>
  );
};

export default EventGallery;
