import { FC, useContext, useState } from "react";
import DialogModal from "src/components/Dialog";
import Transfer from "../modals/Transfer";
import styles from "./nairaWallet.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Backdrop from "src/assets/images/bgImage.svg";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { AppContext, ValueProps } from "src/context";
import { useQuery } from "@apollo/client";
import {
  getEventWallets,
  getEventWalletsVariables,
  getEventWallets_getWalletsV1_transaction_history_user,
} from "src/types/api";
import { GetEventWallets } from "src/graphql/queries/wallets.queries";
import InnerLoading from "src/components/InnerLoading";
import { useNavigate } from "react-router-dom";

type WalletProps = {};

type HistoryProps = {
  user: getEventWallets_getWalletsV1_transaction_history_user | null;
  id: number | string;
  amount: number | string;
  credit: boolean;
  stage: string;
};

const History: FC<HistoryProps> = (props) => {
  return (
    <div key={uuidv4()} className={styles["nairaWallet__main__content__item"]}>
      <p>
        You {props.credit ? "received" : "sent"} funds{" "}
        {props.credit ? "from" : "to"}{" "}
        <span>
          {!props.user ? "Anonymous" : props.user.first_name}{" "}
          {!props.user?.last_name ? "" : props.user.last_name}
        </span>{" "}
        ({props.stage})
      </p>
      <div
        className={
          props.credit
            ? styles["nairaWallet__main__content__item-credit"]
            : styles["nairaWallet__main__content__item-debit"]
        }
      >
        N{Number(props.amount).toLocaleString()}
      </div>
    </div>
  );
};

const NairaWallet: FC<WalletProps> = (props) => {
  const [open, setOpen] = useState(false);
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { data: eventWalletDetails, loading: eventWalletDetailsLoading } =
    useQuery<getEventWallets, getEventWalletsVariables>(GetEventWallets, {
      skip: !eventId,
      variables: {
        eventId: !!eventId ? eventId : 0,
      },
      fetchPolicy: "network-only",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const balance =
    !eventWalletDetailsLoading &&
    !!eventWalletDetails?.getWalletsV1.find(
      (item) => !!item && item.currency === "ngn"
    )?.balance
      ? eventWalletDetails?.getWalletsV1.find(
          (item) => !!item && item.currency === "ngn"
        )?.balance!
      : 0;

  if (eventWalletDetailsLoading) {
    return (
      <div className={styles["nairaWallet__loading"]}>
        <InnerLoading />
      </div>
    );
  }

  return (
    <div className={styles["nairaWallet"]}>
      <header className={styles["nairaWallet__header"]}>
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              onClick={() => setOpen(true)}
              className={styles["nairaWallet__header__trigger"]}
            >
              <span>Transfer Funds</span>
            </div>
          }
        >
          <Transfer setOpen={setOpen} />
        </DialogModal>
      </header>

      {!!eventWalletDetails && (
        <main className={styles["nairaWallet__main"]}>
          <div className={styles["nairaWallet__main__top"]}>
            <div className={styles["nairaWallet__backdrop"]}>
              <LazyLoadImage
                src={Backdrop}
                placeholderSrc={Backdrop}
                alt="Backdrop"
                effect="blur"
                width="100%"
                height="100%"
                className={styles["nairaWallet__backdrop__image"]}
              />
            </div>

            <div className={styles["nairaWallet__main__block"]}>
              <h1>
                {balance.toLocaleString() ?? "0"}{" "}
                {eventWalletDetails?.getWalletsV1
                  .find((item) => !!item && item.currency === "ngn")
                  ?.currency?.toUpperCase() ?? "NGN"}
              </h1>
              {/* <div className={styles["nairaWallet__main__block__acc-number"]}> */}
              {/* <div className={styles["nairaWallet__main__block__group"]}>
                  <h3>
                    {eventWalletDetails?.getWalletsV1.find(
                      (item) => !!item && item.currency === "ngn"
                    )?.name ?? ""}
                  </h3>
                  <p>Account Name</p>
                  <h4>
                    {eventWalletDetails?.getWalletsV1.find(
                      (item) => !!item && item.currency === "ngn"
                    )?.virtual_account_number ?? ""}
                  </h4>
                  <p>
                    {eventWalletDetails?.getWalletsV1.find(
                      (item) => !!item && item.currency === "ngn"
                    )?.virtual_bank_name ?? ""}
                  </p>
                </div> */}
              {/* <div
                  className={styles["nairaWallet__main__block__group-copy"]}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `Account Name: ${
                          eventWalletDetails?.getWalletsV1
                            .find((item) => !!item && item.currency === "ngn")
                            ?.name?.toUpperCase() ?? ""
                        }, Bank Name: ${
                          eventWalletDetails?.getWalletsV1
                            .find((item) => !!item && item.currency === "ngn")
                            ?.virtual_bank_name.toUpperCase() ?? ""
                        }, Account Number: ${
                          eventWalletDetails?.getWalletsV1.find(
                            (item) => !!item && item.currency === "ngn"
                          )?.virtual_account_number ?? ""
                        }`
                      )
                      .then(() => {
                        toast.success("Copied account details to clipboard");
                      })
                  }
                >
                  <Icon iconName="copy" />
                </div> */}
              {/* </div> */}
            </div>
          </div>

          <div className={styles["nairaWallet__main__content"]}>
            <p>Wallet Transactions</p>
            {/*
                A bunch of null checks to make the compiler happy. Change when you have time.
                ===============================================================
                Filtering out the curency because we can only show ngn transactions for now.
              */}
            {!!eventWalletDetails.getWalletsV1 &&
            !!eventWalletDetails.getWalletsV1.find(
              (item) => !!item && item.currency === "ngn"
            ) &&
            !!eventWalletDetails.getWalletsV1?.find(
              (item) => !!item && item.currency === "ngn"
            )?.transaction_history &&
            eventWalletDetails.getWalletsV1.find(
              (item) => !!item && item.currency === "ngn"
            )?.transaction_history?.length! > 0
              ? // sutff actually starts here
                eventWalletDetails.getWalletsV1
                  .find((item) => !!item && item.currency === "ngn")
                  ?.transaction_history!.map((item) =>
                    !!item ? (
                      <History
                        key={uuidv4()}
                        user={item.user}
                        id={item.id}
                        amount={item.amount}
                        credit={item.type === "credit"}
                        stage={item.stage}
                      />
                    ) : (
                      <></>
                    )
                  )
              : "No transactions yet."}
          </div>
        </main>
      )}
    </div>
  );
};

export default NairaWallet;
