import { useState, useContext, FC } from "react";
// import Icon from "src/components/Icon";
import styles from "./invitationCard.module.scss";
// import Button from "src/components/Button";
import TemplateCard from "./TemplateCard";
import UploadCard from "./UploadCard";
import { useQuery } from "@apollo/client";
import { InvitationCardTemplate } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Backdrop from "src/assets/images/bgImage.svg";
import DialogModal from "src/components/Dialog";
import { useNavigate } from "react-router-dom";

type OrientationType = "portrait" | "landscape";

const InvitationCard: FC = () => {
  const [image, setImage] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [orientation] = useState<OrientationType>("landscape");

  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { loading: invitationCardLoading } = useQuery(InvitationCardTemplate, {
    variables: {
      eventId: eventId,
    },
    onCompleted(response) {
      if (!!response?.invitationCard?.template) {
        setImage(response?.invitationCard.template);
      }
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["invitationCard__container"]}>
      <div className={styles["invitationCard"]}>
        <div className={styles["invitationCard__main"]}>
          <div className={styles["invitationCard__main-content"]}>
            <div className={styles["invitationCard__main-template"]}>
              <div className={styles["invitationCard__main-template-title"]}>
                <h5>Invitation Cards</h5>
              </div>
              <div
                className={styles["invitationCard__main-template-container"]}
              >
                <TemplateCard title="Current" image={image} />
              </div>
            </div>

            <div className={styles["invitationCard__main-preview"]}>
              <div className={styles["invitationCard__backdrop"]}>
                <LazyLoadImage
                  src={Backdrop}
                  placeholderSrc={Backdrop}
                  alt="Backdrop"
                  effect="blur"
                  width="100%"
                  height="100%"
                  className={styles["invitationCard__backdrop__image"]}
                />
              </div>
              <div className={styles["invitationCard__main-preview__top"]}>
                {/* <h2>Preview</h2> */}

                <div className={styles["invitationCard__main-views"]}>
                  {/* <div className={styles["invitationCard__main-views-box"]}>
                    <button
                      className={`${
                        styles["invitationCard__main-views-orientation"]
                      }
                        ${
                          orientation === "landscape" &&
                          styles[
                            "invitationCard__main-views-orientation__active"
                          ]
                        }`}
                      onClick={() => setOrientation("landscape")}
                    >
                      <Icon iconName="landscape" />
                    </button>
                    <button
                      className={`${
                        styles["invitationCard__main-views-orientation"]
                      }
                        ${
                          orientation === "portrait" &&
                          styles[
                            "invitationCard__main-views-orientation__active"
                          ]
                        }`}
                      onClick={() => setOrientation("portrait")}
                    >
                      <Icon iconName="potrait" />
                    </button>
                  </div> */}
                </div>

                <div
                  className={styles["invitationCard__main-preview__buttons"]}
                >
                  <div
                    className={styles["invitationCard__alert-upload-button"]}
                  >
                    <DialogModal
                      trigger={
                        <div
                          className={
                            styles[
                              "invitationCard__alert-upload-button__actual"
                            ]
                          }
                        >
                          Upload IV
                        </div>
                      }
                      open={openUpload}
                      onOpenChange={setOpenUpload}
                    >
                      <UploadCard setToggleModal={setOpenUpload} />
                    </DialogModal>
                  </div>
                  {/* <div className={styles["invitationCard__alert-save-button"]}>
                    <Button type="button" label="select template">
                      Select Template
                    </Button>
                  </div> */}
                </div>
              </div>
              <div
                className={styles["invitationCard__main-preview-box-container"]}
              >
                <div
                  data-image={Boolean(image)}
                  className={`
                  ${styles["invitationCard__main-preview-box"]}



                  ${
                    orientation === "portrait" &&
                    styles["invitationCard__main-preview-box__portrait"]
                  }`}
                >
                  {invitationCardLoading ? (
                    <InnerLoading />
                  ) : (
                    image && (
                      <LazyLoadImage
                        src={image}
                        placeholderSrc={Backdrop}
                        alt="invitationCard"
                        effect="blur"
                        className={
                          styles["invitationCard__main-preview-box-image"]
                        }
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitationCard;
