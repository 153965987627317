import { gql } from "@apollo/client";

export const GetCurrencies = gql`
  query getCurrencies {
    currencyV1 {
      name
      symbol
      currency
    }
  }
`;

export const GetEventWallets = gql`
  query getEventWallets($eventId: Int!) {
    getWalletsV1(eventId: $eventId) {
      id
      name
      code
      balance
      currency
      virtual_account_number
      virtual_bank_name
      transaction_history {
        id
        reference
        payment_processor
        description
        event_uuid
        user_uuid
        full_name
        user {
          id
          uuid
          email
          first_name
          image
          last_name
          other_name
          title
          gender
          phone_number
          verified
          guest
          created_at
          updated_at
          lastLoggedInAt
          user_details {
            id
            user_id
            image
            age
            languages
            address
            city
            state
            Country
            created_at
            updated_at
          }
        }
        wallet_id
        wallet_code
        currency
        amount
        status
        stage
        type
        item_id
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const GetWalletTransactionHistory = gql`
  query getWalletTransactionHistory($walletId: String!) {
    getWalletTransactionHistoryV1(walletId: $walletId) {
      id
      description
      event_uuid
      user {
        id
        first_name
        last_name
        phone_number
        guest
      }
      wallet_id
      wallet_code
      currency
      amount
      item_id
      type
      stage
    }
  }
`;

export const TransferToBankV2 = gql`
  mutation transferToBankV2($payload: transferToBankRequestV2!) {
    transferToBankV2(payload: $payload) {
      amount
      id
      event_uuid
      currency
      wallet_code
      user {
        first_name
        id
        last_name
        email
      }
    }
  }
`;
