export const typeDefs = `
directive @isAuthenticated on FIELD_DEFINITION

type AcceptEventTeam {
  event_id: Int!
  event_name: String!
  first_name: String!
  last_name: String!
  event_wallet: [Wallet!]
  role: eventTeamRole!
}

type Account {
  accountName: String
  accountNumber: String
}

input AddContactReq {
  first_name: String!
  other_name: String
  last_name: String
  title: String
  phone: String!
  email: String
}

input AddEventPartRequest {
  event_id: Int!
  time: String!
  name: String!
  welcome_msg: String
  address: String!
  part: String!
  city: String
  state: String
  country: String
  map_coordinate: String
}

input AddEventVendorRequest {
  event_id: Int!
  name: String
  role: String!
  email: String
  phone_number: String!
  instagram_handle: String
  facebook: String
  twitter: String
  image: String
}

input AddOwnerRequest {
  event_id: Int!
  user_id: Int!
  admin: Boolean
}

input AddToInviteListReq {
  id: Int
  first_name: String!
  last_name: String!
  other_name: String
  title: String
  phone: String!
  category_id: Int
  email: String
}

type AlbumMedia {
  id: Int!
  name: String
  url: String
  tags: [AlbumMediaTag]!
  type: String
  created_at: Time
}

enum AlbumMediaTag {
  bride
  groom
  family
  friends
  bridesmaid
  best_man
  others
}

type AuthResponse {
  refresh_token: String!
  refresh_expiry: Time
  access_token: String!
  access_expiry: Time
  user: User
}

type AvailableCurrency {
  """e.g Dollar, Naira"""
  name: String!

  """e.g $, ₦"""
  symbol: String

  """e.g USD, NGN"""
  currency: Currency!
}

type BackStory {
  id: Int!
  title: String
  event_id: Int!
  role: BackStoryRole!
  image: String
  story: String!
  created_at: Time
  updated_at: Time
}

enum BackStoryRole {
  Bride
  Groom
}

type Bank {
  id: Int
  code: String
  name: String
}

type Banks {
  id: Int
  createdAt: String
  updatedAt: String
  name: String
  slug: String
  code: String
  long_code: String
  gateway: Int
  active: Boolean
  is_deleted: Boolean
}

type Beneficiary {
  id: String!
  event_id: String!
  wallet_id: String!
  recipient_code: String!
  payment_processor: String!
  created_at: Time!
  updated_at: Time
}

type Category {
  id: Int!
  event_id: Int!
  name: String!
  message: String
  created_at: Time
  updated_at: Time
}

type Contact {
  id: Int!
  first_name: String!
  other_name: String
  last_name: String!
  title: String
  phone: String!
  email: String
  created_at: Time
  updated_at: Time
}

type CreateEventResponse {
  id: Int!
  name: String!
  created_at: Time!
  updated_at: Time
}

input CreateInvitationReq {
  event_id: Int!
  category_id: Int!
  template_url: String
}

input CreateMyVendorProfileReq {
  brand_name: String!
  logo: String
  phone_number: String!
  email: String!
  address: String!
  description: String!
}

input CreateMyVendorServiceReq {
  service_name: String!
  phone_number: String!
  email: String!
  address: [String]
  description: String
  service_category_id: UUID!
}

input CreateMyVendorServiceShowcase {
  name: String!
  service_id: UUID!
  description: String
  tags: [String]
  media: String
}

scalar Currency

scalar E164

"""Custom scalar type for emails"""
scalar Email

type Event {
  id: Int!
  uuid: String!
  name: String!
  wallet_created: Boolean
  hash_tag: String

  """deprecated"""
  wallet_code: String
  slug: String
  toast: String
  welcome_msg: String
  created_at: Time
  updated_at: Time
  date: String
  event_wallet: [Wallet]

  """deprecated"""
  event_owners: [EventOwner!]!
  event_type: EventType
  event_parts: [EventPart!]
  event_preference: EventPreference
  is_admin: Boolean!
}

type EventOwner {
  id: Int!
  user_id: Int!
  event_id: Int!
  admin: Boolean
  role: String
  user: User
  created_at: Time
  updated_at: Time
}

type EventPart {
  id: Int!
  event_id: Int!
  name: String!
  part: String!
  time: String!
  welcome_msg: String
  address: String!
  city: String
  state: String
  country: String
  map_coordinate: String
  created_at: Time
  updated_at: Time
}

type EventPlan {
  event_id: Int!
  event_details: Boolean!
  invitation_card: Boolean!
  categories: Boolean!
  build_invite_list: Boolean!
  send_out_invites: Boolean!
  add_wishlist: Boolean!
  website_template: Boolean!
  add_back_story: Boolean!
  upload_pictures: Boolean!
  add_event_vendors: Boolean!
}

type EventPreference {
  id: Int!
  event_id: Int!
  food: Boolean
  ads: Boolean
  asoebi_selection: Boolean
  gifts: Boolean
  store: Boolean
  vendor_store: Boolean
  reservation: Boolean
  dress_code: Boolean
  map: Boolean
  smart_send: Boolean
  private: Boolean
  created_at: Time
  updated_at: Time
}

type EventSetting {
  id: Int!
  event_id: Int!
  created_at: Time
  updated_at: Time
}

type EventSiteTemplate {
  id: Int!
  event_type: String!
  image: String!
  description: String!
  created_by: String!
  contact: String!
  created_at: Time
  updated_at: Time
}

type EventStoreItem {
  id: Int!
  order: Int
  event_id: Int!
  status: Boolean
  currency: Currency
  item_name: String
  item_price: Float
  item_image: String
  created_at: Time
  updated_at: Time
}

type EventStorePurchase {
  id: Int!
  event_id: Int!
  event_store_item_id: Int
  item: EventStoreItem
  buyer_id: Int
  user: User
  paid: Boolean
  created_at: Time
  updated_at: Time
}

type EventType {
  id: Int!
  name: String!
  description: String!
}

type EventVendor {
  id: Int!
  event_id: Int!
  name: String
  role: String
  email: String
  phone_number: String!
  instagram_handle: String
  facebook: String
  twitter: String
  image: String
  created_at: Time
  updated_at: Time
}

type FoodOption {
  id: Int!
  event_id: Int!
  food_name: String
  food_type: String
  image: String
  created_at: Time
  updated_at: Time
}

enum Gender {
  female
  male
}

type GuestFoodOption {
  id: Int!
  invitation_list_id: Int!
  user_id: Int!
  user: User
  event_id: Int!
  food_option_id: Int!
  food_option: FoodOption
  created_at: Time
  updated_at: Time
}

type GuestPerCategory {
  category_id: Int!
  category_name: String!
  message: String
  num_guest: Int
}

type InawoVendorAdsPlan {
  id: UUID!
  name: String!
  image: String
  description: String!
  price: Float!
  currency: String!
  duration_in_secs: Int!
  created_at: Time!
  updated_at: Time
}

type InawoVendorServiceCategory {
  id: UUID!
  Name: String!
  Description: String
  Images: [String]
  created_at: Time
  updated_at: Time
}

type InawoVendorSubscriptionPlan {
  id: UUID!
  name: String!
  image: String
  description: String!
  price: Float!
  currency: String!
  duration_in_secs: Int!
  created_at: Time!
  updated_at: Time
}

type InvitationCard {
  id: Int
  event_id: Int
  template: String
  created_at: Time
  updated_at: Time
}

type InvitationList {
  id: Int!
  event_id: Int!
  first_name: String!
  other_name: String
  last_name: String!
  title: String
  phone: String!
  email: String
  sent: Boolean!
  category_id: Int
  category: Category
  rsvp_status: String
  created_at: Time
  updated_at: Time
}

type ListMeta {
  total: Int
  skipped: Int
  perPage: Int
  page: Int
  pageCount: Int
}

input LoginRequest {
  username: String!
  password: String!
}

scalar Map

type Message {
  id: Int!
  event_id: Int!
  user_id: Int!
  user: User
  content: String
  reply: String
  status: Boolean!
  created_at: Time
  updated_at: Time
}

type Meta {
  next: Int
  page: Int
  pageSize: Int
}

type Mutation {
  hiUser(name: String!): String!
  signupByPhoneNumber(input: SignUpRequest!): Boolean
  signupByInviteLink(eventId: Int!, inviteToken: String!): AuthResponse
  login(input: LoginRequest!): AuthResponse!
  logout: String!
  resetPassword(OTP: String!, password: String!, phone: String!): String!
  changePassword(oldPassword: String!, newPassword: String!): String
  verifyPhone(otp: String!, phone: String!): AuthResponse!
  resendVerifyPhoneOTP(phone: String!): Boolean
  sendForgetPasswordOTP(phone: String!): String!
  updateUserDetails(input: UpdateUserDetailsRequest!): UserDetails
  updateUserProfile(input: UpdateUserProfileRequest!): User
  addToWaitList(email: String!): String
  createEvent(name: String!, event_type_id: Int!, hashTag: String, welcomeMsg: String, role: String): Event
  updateEvent(eventId: Int!, name: String, event_type_id: Int, hashTag: String, toast: String, welcomeMsg: String): Event
  deleteEvent(eventId: Int!): String
  addEventPart(input: AddEventPartRequest!): EventPart!
  updateEventPart(input: UpdateEventPartRequest!): EventPart!
  deleteEventPart(eventId: Int!, partId: Int!): String!
  updateEventPreference(input: UpdateEventPreferenceRequest): EventPreference
  updateEventSlug(eventId: Int!, slug: String!): Event
  addContact(contacts: [AddContactReq!]!): String
  addToInviteList(eventId: Int!, invite_list: [AddToInviteListReq!]!): String
  updateInviteList(eventId: Int!, invite_list: [UpdateInviteListReq!]!): String
  removeFromInvitationList(event_id: Int!, invitation_list_ids: [Int!]!): String!
  updateInvitationCard(eventId: Int!, template: String!): InvitationCard
  sendInvitation(eventId: Int!, all: Boolean!, invitationListIds: [Int!]): String
  authenticateInviteLink(inviteToken: String!): User!
  updateRsvpStatus(event_id: Int!, status: String!): Boolean!
  addEventTeamMember(team: TeamMember!): Boolean
  acceptEventTeam(token: String!): AcceptEventTeam
  removeTeamMember(event_id: Int!, owner_id: Int!): Boolean
  addEventVendor(input: AddEventVendorRequest!): EventVendor
  updateEventVendor(input: UpdateEventVendorRequest!): EventVendor
  removeEventVendor(eventId: Int!, vendorId: Int!): String
  createGuestCategory(event_id: Int!, name: String!, message: String): Category!
  updateGuestCategory(event_id: Int!, categoryId: Int!, name: String!, message: String): Category!
  deleteGuestCategory(event_id: Int!, categoryId: Int!): String!
  updateAlbumMedia(eventId: Int!, mediaId: Int!, name: String, url: String, tags: [AlbumMediaTag], type: String): AlbumMedia
  addMedia(eventId: Int!, albumId: Int, name: String, url: String!, tags: [AlbumMediaTag!]!, type: String): AlbumMedia!

  """Deprecated"""
  deleteAlbumMedia(eventId: Int!, mediaId: Int!): String
  deleteAlbumMedias(eventId: Int!, mediaId: [Int!]!): String
  createMessage(eventId: Int!, content: String!): String!
  updateMessage(messageId: Int!, content: String!): Message!
  replyMessage(eventId: Int!, messageId: Int!, reply: String!): String
  updateMessageStatus(eventId: Int!, messages: [UpdateMessageStatusReq!]!): String!
  deleteMessageById(eventId: Int!, messageId: Int!): String!
  addWishListItem(eventId: Int!, name: String!, wishlistId: Int, image: String!, amount: Float!, currency: Currency): WishListItem
  updateWishListItem(eventId: Int!, itemId: Int!, name: String, image: String, amount: Float, currency: Currency): WishListItem
  appreciateGift(eventId: Int!, itemId: Int!, appreciation: String!): WishListItem
  deleteWishListItem(eventId: Int!, itemId: Int!): String!
  addBackStory(eventId: Int!, input: addBackStoryReq!): BackStory
  updateBackStory(eventId: Int!, input: updateBackStoryReq!): BackStory
  deleteBackStory(eventId: Int!, id: Int!): String
  createFoodOption(eventId: Int!, mealType: String!, foodName: String!, image: String): FoodOption
  updateFoodOption(eventId: Int!, foodOptionId: Int!, mealType: String, foodName: String, image: String): FoodOption
  deleteFoodOption(eventId: Int!, foodOptionId: Int!): String
  createGuestFoodOption(eventId: Int!, foodOptionId: Int!): GuestFoodOption
  deleteGuestFoodOption(eventId: Int!, guestFoodOptionId: Int!): String
  addEventStoreItem(eventId: Int!, itemName: String!, itemImage: String, itemPrice: Float!, currency: Currency!): EventStoreItem
  updateEventStoreItem(eventId: Int!, itemId: Int!, itemName: String, itemImage: String, itemPrice: Float, currency: Currency): EventStoreItem
  deleteEventStoreItem(eventId: Int!, itemId: Int!): String
  verifyTransaction(reference: String!): TransactionV1!

  """called when payment for a wish list item is successful"""
  payForWishListItemV1(payload: payForWishlistItemRequest!): Boolean!

  """called when payment for cash gift is successful"""
  payForCashGiftV1(payload: payForCashGiftRequest!): Boolean!

  """called when payment for spray money is successful"""
  payForSprayMoneyV1(payload: payForSprayMoneyRequest!): Boolean!

  """creates a transfer recipient code used by transferToBankV2"""
  createTransferRecipient(bankCode: String!, bankAccountNumber: String!): TransferRecipient

  """creates a wallet for an event"""
  CreateEventWallets(eventId: String!, eventName: String!): [Wallet]

  """called when is a user wants to withdraw money to a bank"""
  transferToBankV2(payload: transferToBankRequestV2!): TransactionV1!
  transferToBank(input: transferFromWallet): Boolean
  initializeSetWalletPin(input: WalletUser!): Boolean!
  verifySetPinRequest(otp: String!, phone: String!): String!
  setWalletPin(phone: String!, password: String!, newPin: String): String!
  helloVendor: String!
  createMyVendorProfile(req: CreateMyVendorProfileReq!): Vendor!
  updateMyVendorProfile(req: UpdateMyVendorProfileReq!): Vendor!
  deleteMyVendorProfile(vendorId: UUID!): Boolean
  createMyVendorService(req: CreateMyVendorServiceReq!): VendorService!
  updateMyVendorService(req: UpdateMyVendorServiceReq!): VendorService!
  getMyVendorServices: [VendorService]
  deleteMyVendorService(vendor_service_id: UUID!): Boolean
  createMyVendorServiceShowcase(req: CreateMyVendorServiceShowcase!): VendorServiceShowcase!
  updateMyVendorServiceShowcase(req: UpdateMyVendorServiceShowcase!): VendorServiceShowcase!
  deleteMyVendorServiceShowcase(serviceId: UUID!, showcaseId: UUID!): Boolean
  bookVendorService(vendorServiceId: UUID!, eventUUID: UUID): Boolean!
  cancelVendorServiceBookings(bookingId: UUID!): Boolean!

  """allows a vendor to accept/decline a vendee's booking"""
  acceptVendorServiceBookings(bookingsId: UUID!, status: vendorServiceBookingStatus!): Boolean!
  pinVendorService(vendorServiceId: UUID!): Boolean!
  unPinVendorService(vendorServiceId: UUID!): Boolean!
  getPinnedVendorServices: [VendorService]
  rateVendorService(vendorServiceId: UUID!, bookingId: UUID!, rating: Int!, comment: String!): VendorRating!
  subscribeToVendorPlan(planId: UUID!, paymentReference: String!, paymentProcessor: String!): Boolean!
  payForVendorAds(adsId: UUID!, vendorServiceID: UUID!, paymentReference: String!, paymentProcessor: String!): Boolean!
}

type Pagination {
  next: String
  back: String
}

scalar Password

type PayStackBankData {
  name: String
  slug: String
  code: String
  longcode: String
  gateway: String
  pay_with_bank: Boolean
  active: Boolean
  is_deleted: Boolean
  country: String
  currency: String
  type: String
  id: Int
  createdAt: Time
  updatedAt: Time
}

type PayStackBanks {
  status: Boolean
  message: String
  data: [PayStackBankData]
  meta: Meta
}

type PinnedVendor {
  id: UUID!
  user_uuid: UUID!
  vendors_id: UUID!
  vendor_service_id: UUID!
  created_at: Time
  updated_at: Time
}

type Query {
  health: Boolean!

  """deprecated"""
  refresh(refresh_token: String!): AuthResponse!
  refreshV2(refreshToken: String!): AuthResponse!
  user: User
  checkEventSlug(slug: String!): Boolean!
  event(eventId: Int!): Event
  eventBySlug(slug: String!): Event
  events(isInvited: Boolean, page: Int, pageSize: Int): [Event]!
  getEventType: [EventType!]!
  guestCategory(eventId: Int!): [Category!]!
  guestCategoryById(eventId: Int!, categoryId: Int!): Category!
  noGuestPerCategory(eventId: Int!): [GuestPerCategory]!
  owners(eventId: Int!): [EventOwner!]!
  eventVendor(eventId: Int!, page: Int, pageSize: Int): [EventVendor]!
  getTeamMember(event_id: Int!): [EventOwner!]!
  contact_list(page: Int, pageSize: Int): [Contact]!
  invitationList(eventId: Int!, page: Int, pageSize: Int): [InvitationList!]!
  invitationCard(eventId: Int!): InvitationCard
  invitationListByCategory(eventId: Int!, categoryId: Int, page: Int, pageSize: Int): [InvitationList!]!
  invitationListBySearch(eventId: Int!, searchTerm: String, page: Int, pageSize: Int): [InvitationList!]!
  invitationListByRSVPStatus(eventId: Int!, rsvpStatus: RSVPStatus!, page: Int, pageSize: Int): [InvitationList!]!
  event_preferences(eventId: Int!): EventPreference!
  eventParts(eventId: Int!): [EventPart!]!

  """returns media by tags"""
  getMediaByTags(eventId: Int!, tags: [AlbumMediaTag!]!, page: Int, pageSize: Int): [AlbumMedia]

  """return all the tags a user can use"""
  getAllAvailableAlbumMediaTags: [AlbumMediaTag!]!
  getAlbumDetails(eventId: Int!, albumId: Int, page: Int, pageSize: Int): [AlbumMedia]
  getApprovedMessages(eventId: Int!, page: Int, pageSize: Int): [Message]
  getMessagesByEvent(eventId: Int!, page: Int, pageSize: Int): [Message]
  getMessageByUser(eventId: Int!, page: Int, pageSize: Int): [Message]
  getWishListItems(eventId: Int!, listId: Int, page: Int, pageSize: Int): [WishListItem]
  getPaidWishListItems(eventId: Int!, listId: Int, page: Int, pageSize: Int): [WishListItem]
  getBackStory(eventId: Int!): [BackStory]
  getEventPlan(event_id: Int!): EventPlan!
  getFoodOptions(eventId: Int!, page: Int, pageSize: Int): [FoodOption!]!
  getGuestFoodOptions(eventId: Int!, page: Int, pageSize: Int): [GuestFoodOption!]!
  getMyGuestFoodOptions(eventId: Int!, userId: Int, page: Int, pageSize: Int): [GuestFoodOption!]!
  getEventStoreItems(eventId: Int!, page: Int, pageSize: Int): [EventStoreItem!]!
  getEventStorePurchaseLog(eventId: Int!, page: Int, pageSize: Int): [EventStorePurchase!]!
  getMyStorePurchaseLog(page: Int, pageSize: Int): [EventStorePurchase!]!

  """
  returns a list of available currencies with their name, symbol and slug(currency)
  """
  currency: [AvailableCurrency]!

  """returns all wallet belonging to a user"""
  getWalletTransactionHistory(walletId: String!, page: Int, pageSize: Int): [TransactionV1!]!

  """
  returns a list of available currencies with thier name, symbol and slug(currency)
      @isAuthenticated
  """
  currencyV1: [AvailableCurrency]!

  """returns all wallet belonging to an event"""
  getWalletsV1(eventId: Int!): [Wallet]!

  """
  returns all wallet transaction history.
      It is paginated and shows the most recent history
      @isAuthenticated
  """
  getWalletTransactionHistoryV1(walletId: String!, page: Int, pageSize: Int): [TransactionV1!]!
  getGiftHistory(eventId: String!, cursor: String, pageSize: String): TransactionWithPagination

  """returns all the banks to make transfer to in naira"""
  getBanks: getBankResponse!
  getBeneficiaries(walletId: String!): [Beneficiary!]
  getBanksWithRave(country: String!): [Bank]!
  resolveAccountWithRave(accountNumber: String!, accountBank: String!): Account
  helloVendor: String!
  getMyVendorProfile: Vendor!

  """returns a list of vendor service categories in Inawo"""
  getInawoVendorServiceCategory: [InawoVendorServiceCategory]
  getMyVendorServices: [VendorService!]
  getVendorServiceShowcase(serviceId: UUID!): [VendorServiceShowcase]

  """
  returns an ARRAY of vendor services and their showcases in random orders
  
  if authenticated, the email and phone number of the vendor is provided
  """
  getVendorServiceRandom: [VendorService]

  """returns a users vendor bookings"""
  getMyVendorBooking: [VendorBooking]

  """returns the users who booked you as a vendor"""
  getMyBookingsAsVendor: [VendorBooking]
  getVendorServiceByVendor(vendorsId: UUID!): [VendorService]
  getVendorServiceByCategory(vendorServiceCategoryId: UUID!): [VendorService]
  getInawoVendorSubscriptionPlans: [InawoVendorSubscriptionPlan!]!
  getInawoVendorAdsPlans: [InawoVendorAdsPlan!]!
  getVendorPaymentHistory: [VendorPaymentHistory!]!
  getFeaturedVendorServices: [VendorService]
}

type RSVP {
  id: Int!
  event_id: Int!
  invitation_list_id: Int!
  field: String!
  value: String
  created_at: Time!
  updated_at: Time
}

type RSVPQuestion {
  id: Int!
  event_id: Int!
  invitation_id: Int!
  question: String!
  question_type: String
  status: Boolean!
  created_at: Time!
  updated_at: Time
}

enum RSVPStatus {
  yes
  no
  maybe
  pending
}

type SendInvitationResponse {
  id: Int!
  error: String!
}

input SignUpRequest {
  phone_number: String!
  email: Email
  password: Password!
  first_name: String!
  last_name: String!
  other_name: String
  title: String
  gender: Gender
}

input TeamMember {
  event_id: Int!
  first_name: String!
  last_name: String!
  other_name: String
  title: String
  email: String
  phone_number: String!
  role: eventTeamRole!
}

scalar Time

enum TransactionTypeEnum {
  credit
  debit
  all
  last
}

type TransactionV1 {
  """used for indexing"""
  id: String!

  """reference -> very important (takes precedence over the id)"""
  reference: String!

  """e.g paystack, monnify, flutterwave ... (all lower case)"""
  payment_processor: String!

  """provides context of transaction"""
  description: String!

  """the id of the event for which the transaction was made"""
  event_id: Int!

  """if the transaction was made by an authenticated user"""
  user_id: Int

  """anonymous or the person's name"""
  full_name: String

  """returns if the transaction was made by an authorized user"""
  user: User

  """a uuid string representing the wallet id"""
  wallet_id: String!

  """deprecated -> same as virtual bank account number"""
  wallet_code: String

  """for now [ USD | NGN ] ...all caps (upper case)"""
  currency: Currency!

  """in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200"""
  amount: Float!

  """
  true|false (a summary of the stage of the transaction) ==>
  
  stage:successful-> true
  &
  stage:failed -> false
  """
  status: String!

  """[ successful | failed | pending ] ... (all lower case)"""
  stage: String!

  """[ credit | debit ] ... (all lower case)"""
  type: String!

  """only provided if the transaction was for payment of a wishlist item"""
  item_id: Int

  """time the transaction was done"""
  created_at: Time

  """last update at"""
  updated_at: Time
}

type TransactionWithPagination {
  meta: Pagination
  transaction: [TransactionV1]
}

type TransferRecipient {
  recipientCode: String!
  AccountName: String!
  PaymentProcessor: String!
}

scalar UUID

input UpdateEventPartRequest {
  id: Int!
  event_id: Int!
  time: String
  name: String
  part: String
  welcome_msg: String
  address: String
  city: String
  state: String
  country: String
  map_coordinate: String
}

input UpdateEventPreferenceRequest {
  id: Int
  event_id: Int!
  food: Boolean
  ads: Boolean
  asoebi_selection: Boolean
  gifts: Boolean
  store: Boolean
  vendor_store: Boolean
  reservation: Boolean
  smart_send: Boolean
  dress_code: Boolean
  map: Boolean
  private: Boolean
}

input UpdateEventSettingRequest {
  id: Int
  event_id: Int!
}

input UpdateEventVendorRequest {
  id: Int!
  event_id: Int!
  name: String
  role: String
  image: String
  facebook: String
  twitter: String
  email: String
  instagram_handle: String
  phone_number: String
}

input UpdateInviteListReq {
  id: Int!
  first_name: String
  last_name: String
  other_name: String
  title: String
  phone: String
  category_id: Int
  email: String
}

input UpdateMessageStatusReq {
  message_id: Int!
  status: Boolean!
}

input UpdateMyVendorProfileReq {
  id: UUID!
  brand_name: String
  logo: String
  phone_number: String
  email: String
  address: [String]
  description: String
}

input UpdateMyVendorServiceReq {
  id: UUID!
  service_name: String
  phone_number: String
  email: String
  address: [String]
  description: String
  service_category_id: UUID
}

input UpdateMyVendorServiceShowcase {
  id: UUID!
  name: String
  service_id: UUID!
  description: String
  tags: [String]
  media: String
}

input UpdateUserDetailsRequest {
  image: String
  age: Int
  languages: [String]
  address: String
  city: String
  state: String
  Country: String
}

input UpdateUserPreferenceRequest {
  field: String!
  value: String!
}

input UpdateUserProfileRequest {
  image: String
  first_name: String
  other_name: String
  last_name: String
  email: String
  phone_number: String
}

"""Users on Inawo"""
type User {
  id: Int!
  uuid: UUID
  email: Email

  """first name of the person"""
  first_name: String!
  image: String

  """last name of the person"""
  last_name: String
  other_name: String
  title: String

  """gender of the person"""
  gender: Gender

  """phone number of the person"""
  phone_number: String!
  verified: Boolean!
  guest: Boolean
  created_at: Time
  updated_at: Time
  lastLoggedInAt: Time
  user_details: UserDetails
}

type UserDetails {
  id: Int!
  user_id: Int!
  image: String
  age: Int
  languages: [String]!
  address: String
  city: String
  state: String
  Country: String
  created_at: Time
  updated_at: Time
}

type Vendor {
  id: UUID!
  user_uuid: UUID!
  brand_name: String!
  description: String
  logo: String
  phone_number: String
  email: String
  address: [String]
  created_at: Time
  updated_at: Time
}

type VendorBooking {
  id: UUID!
  user_uuid: UUID!
  event_uuid: UUID!
  vendors_id: UUID!
  vendor_service_id: UUID!
  booking_status: vendorServiceBookingStatus!
  created_at: Time!
  updated_at: Time
}

type VendorPaymentHistory {
  id: UUID!
  vendors_id: UUID!
  purpose: String!
  amount: Float!
  currency: Currency!
  payment_reference: String!
  payment_processor: String!
  created_at: Time!
  updated_at: Time
}

type VendorRating {
  id: UUID!
  vendors_id: UUID!
  event_uuid: UUID
  vendor_service_id: UUID!
  vendor_bookings_id: UUID!
  comment: String!
  rating: Int!
  user_uuid: UUID!
  created_at: Time!
  updated_at: Time
}

type VendorService {
  id: UUID!
  interests: Int!
  vendors_id: UUID!
  vendor_service_category_id: UUID!
  service_name: String!
  description: String
  phone_number: String
  email: String
  address: [String]
  showcase(page: Int, pageSize: Int): [VendorServiceShowcase]
  created_at: Time
  updated_at: Time
}

type VendorServiceShowcase {
  id: UUID!
  vendors_id: UUID!
  vendor_service_category_id: UUID!
  vendor_service_id: UUID!
  name: String!
  media: String
  description: String
  tags: [String]
  created_at: Time
  updated_at: Time
}

type Wallet {
  """the uuid that identifies the wallet"""
  id: String!
  name: String

  """deprecated -> same as virtual_account_number"""
  code: String

  """calculated on call"""
  balance: Float!

  """currently only -> [ USD|NGN ]"""
  currency: Currency!

  """a virtual bank account number associated with the wallet"""
  virtual_account_number: String!

  """the bank providing the virtual bank account"""
  virtual_bank_name: String!

  """history of transactions done on the wallet"""
  transaction_history: [TransactionV1]

  """time when the wallet was created"""
  created_at: Time

  """last updated at"""
  updated_at: Time
}

input WalletUser {
  id: Int!
  uuid: UUID
  email: Email

  """first name of the person"""
  first_name: String!
  image: String

  """last name of the person"""
  last_name: String
  other_name: String
  title: String

  """gender of the person"""
  gender: Gender

  """phone number of the person"""
  phone: String!
}

type WishListItem {
  id: Int!
  uuid: UUID
  wish_list_id: Int
  event_id: Int!
  name: String
  image: String
  amount: Float
  currency: String
  paid: Boolean
  paid_by: Int
  user: User
  qty: Int
  appreciation: String
  created_at: Time
  updated_at: Time
}

input addBackStoryReq {
  title: String
  image: String
  role: BackStoryRole!
  story: String!
}

enum eventTeamRole {
  bride
  groom
  planner
}

type getBankResponse {
  Meta: ListMeta
  data: [PayStackBankData]
}

input payForCashGiftRequest {
  """reference from paystack"""
  reference: String!

  """USD/NGN this is necessary to select the wallet to credit"""
  currency: Currency!

  """id of the event, please oo! this has to be correct"""
  eventID: Int!
  amount: Float

  """pending | failed => false or successful => true"""
  status: Boolean

  """first_name [other_name] last_name"""
  fullName: String

  """phone number of the person paying e.g +2349045057268"""
  phone: String

  """email of the person paying e.g hello@inawo.com"""
  email: String

  """an alias for the creditor, can be any string"""
  creditorAlias: String
}

input payForSprayMoneyRequest {
  """USD/NGN"""
  currency: Currency!

  """id of the event, please oo! this has to be correct"""
  eventId: Int!

  """the amount paid in kobo/cent depending on the currency"""
  amount: Float!

  """the units to be sprayed"""
  units: String!

  """pending | failed => false or successful => true"""
  status: Boolean!

  """first_name [other_name] last_name"""
  full_name: String!

  """phone number of the person paying e.g +2349045057268"""
  phone: E164

  """email of the person paying e.g hello@inawo.com"""
  email: Email!
}

input payForWishlistItemRequest {
  """USD/NGN"""
  currency: Currency!

  """wish list item id"""
  itemId: String!

  """name of the wish list item"""
  itemName: String!

  """reference from paystack"""
  reference: String!

  """id of the event, please oo! this has to be correct"""
  eventId: Int!

  """amount in the base units e.g kobo or cents"""
  amount: Float!

  """pending | failed => false or successful => true"""
  status: Boolean!

  """first_name [other_name] last_name"""
  full_name: String!

  """phone number of the person paying e.g +2349045057268"""
  phone: E164

  """email of the person paying e.g hello@inawo.com"""
  email: Email!

  """an alias for the creditor, can be any string"""
  creditorAlias: String
}

input rsvpResp {
  id: Int!
  event_id: Int!
  invitation_list_id: Int!
  field: String!
  value: String
}

input transferFromWallet {
  currency: Currency!
  eventId: Int!
  walletId: String!
  amount: Float!
  accountNumber: String!
  accountBank: String!
  narration: String
  bankCode: String!
  reference: String!
}

input transferToBankRequestV2 {
  """USD/NGN"""
  currency: Currency!

  """id of the event, please oo! this has to be correct"""
  eventId: Int!

  """id of the wallet"""
  walletId: String!

  """the amount paid in kobo/cent depending on the currency"""
  amount: Float!

  """recipient code"""
  recipient_code: String!

  """e.g  Food Vendor"""
  reason: String
}

input updateBackStoryReq {
  id: Int!
  role: BackStoryRole
  title: String
  image: String
  story: String
}

enum vendorServiceBookingStatus {
  accepted
  decline
  pending
}
`;
