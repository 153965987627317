/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signupByPhoneNumber
// ====================================================

export interface signupByPhoneNumber {
  signupByPhoneNumber: boolean | null;
}

export interface signupByPhoneNumberVariables {
  input: SignUpRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: loginUser
// ====================================================

export interface loginUser_login_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
}

export interface loginUser_login {
  __typename: "AuthResponse";
  access_expiry: any | null;
  access_token: string;
  refresh_expiry: any | null;
  refresh_token: string;
  user: loginUser_login_user | null;
}

export interface loginUser {
  login: loginUser_login;
}

export interface loginUserVariables {
  input: LoginRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyPhone
// ====================================================

export interface verifyPhone_verifyPhone_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  id: number;
  /**
   * last name of the person
   */
  last_name: string | null;
}

export interface verifyPhone_verifyPhone {
  __typename: "AuthResponse";
  access_expiry: any | null;
  access_token: string;
  refresh_expiry: any | null;
  refresh_token: string;
  user: verifyPhone_verifyPhone_user | null;
}

export interface verifyPhone {
  verifyPhone: verifyPhone_verifyPhone;
}

export interface verifyPhoneVariables {
  otp: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendForgetPassword
// ====================================================

export interface sendForgetPassword {
  sendForgetPasswordOTP: string;
}

export interface sendForgetPasswordVariables {
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendVerifyPhoneOTP
// ====================================================

export interface resendVerifyPhoneOTP {
  resendVerifyPhoneOTP: boolean | null;
}

export interface resendVerifyPhoneOTPVariables {
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addEventPart
// ====================================================

export interface addEventPart_addEventPart {
  __typename: "EventPart";
  id: number;
  event_id: number;
  time: string;
  part: string;
  name: string;
  address: string;
}

export interface addEventPart {
  addEventPart: addEventPart_addEventPart;
}

export interface addEventPartVariables {
  input: AddEventPartRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventPart
// ====================================================

export interface updateEventPart_updateEventPart {
  __typename: "EventPart";
  id: number;
  event_id: number;
  time: string;
  part: string;
  name: string;
  address: string;
}

export interface updateEventPart {
  updateEventPart: updateEventPart_updateEventPart;
}

export interface updateEventPartVariables {
  input: UpdateEventPartRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEventPart
// ====================================================

export interface deleteEventPart {
  deleteEventPart: string;
}

export interface deleteEventPartVariables {
  eventId: number;
  partId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addEventVendor
// ====================================================

export interface addEventVendor_addEventVendor {
  __typename: "EventVendor";
  id: number;
  instagram_handle: string | null;
  event_id: number;
  role: string | null;
  email: string | null;
  name: string | null;
  phone_number: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface addEventVendor {
  addEventVendor: addEventVendor_addEventVendor | null;
}

export interface addEventVendorVariables {
  input: AddEventVendorRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventVendor
// ====================================================

export interface updateEventVendor_updateEventVendor {
  __typename: "EventVendor";
  id: number;
  image: string | null;
  instagram_handle: string | null;
  name: string | null;
  role: string | null;
  event_id: number;
  phone_number: string;
}

export interface updateEventVendor {
  updateEventVendor: updateEventVendor_updateEventVendor | null;
}

export interface updateEventVendorVariables {
  input: UpdateEventVendorRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeEventVendor
// ====================================================

export interface removeEventVendor {
  removeEventVendor: string | null;
}

export interface removeEventVendorVariables {
  eventId: number;
  vendorId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createGuestCategory
// ====================================================

export interface createGuestCategory_createGuestCategory {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface createGuestCategory {
  createGuestCategory: createGuestCategory_createGuestCategory;
}

export interface createGuestCategoryVariables {
  event_id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateGuestCategory
// ====================================================

export interface updateGuestCategory_updateGuestCategory {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  message: string | null;
}

export interface updateGuestCategory {
  updateGuestCategory: updateGuestCategory_updateGuestCategory;
}

export interface updateGuestCategoryVariables {
  eventId: number;
  categoryId: number;
  name: string;
  message?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventPreference
// ====================================================

export interface updateEventPreference_updateEventPreference {
  __typename: "EventPreference";
  smart_send: boolean | null;
  event_id: number;
  id: number;
}

export interface updateEventPreference {
  updateEventPreference: updateEventPreference_updateEventPreference | null;
}

export interface updateEventPreferenceVariables {
  input?: UpdateEventPreferenceRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMessage
// ====================================================

export interface createMessage {
  createMessage: string;
}

export interface createMessageVariables {
  eventId: number;
  content: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: replyMessage
// ====================================================

export interface replyMessage {
  replyMessage: string | null;
}

export interface replyMessageVariables {
  eventId: number;
  messageId: number;
  reply: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addToInviteList
// ====================================================

export interface addToInviteList {
  addToInviteList: string | null;
}

export interface addToInviteListVariables {
  eventId: number;
  invite_list: AddToInviteListReq[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeFromInvitationList
// ====================================================

export interface removeFromInvitationList {
  removeFromInvitationList: string;
}

export interface removeFromInvitationListVariables {
  event_id: number;
  invitation_list_ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInviteList
// ====================================================

export interface updateInviteList {
  updateInviteList: string | null;
}

export interface updateInviteListVariables {
  eventId: number;
  invite_list: UpdateInviteListReq[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addBackStory
// ====================================================

export interface addBackStory_addBackStory {
  __typename: "BackStory";
  title: string | null;
  id: number;
  event_id: number;
  role: BackStoryRole;
  story: string;
  image: string | null;
}

export interface addBackStory {
  addBackStory: addBackStory_addBackStory | null;
}

export interface addBackStoryVariables {
  eventId: number;
  input: addBackStoryReq;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBackStory
// ====================================================

export interface updateBackStory_updateBackStory {
  __typename: "BackStory";
  title: string | null;
  id: number;
  event_id: number;
  role: BackStoryRole;
  story: string;
}

export interface updateBackStory {
  updateBackStory: updateBackStory_updateBackStory | null;
}

export interface updateBackStoryVariables {
  eventId: number;
  input: updateBackStoryReq;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBackStory
// ====================================================

export interface deleteBackStory {
  deleteBackStory: string | null;
}

export interface deleteBackStoryVariables {
  eventId: number;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addWishListItem
// ====================================================

export interface addWishListItem_addWishListItem {
  __typename: "WishListItem";
  id: number;
  wish_list_id: number | null;
  name: string | null;
  image: string | null;
  amount: number | null;
}

export interface addWishListItem {
  addWishListItem: addWishListItem_addWishListItem | null;
}

export interface addWishListItemVariables {
  eventId: number;
  name: string;
  wishlistId?: number | null;
  image: string;
  amount: number;
  currency?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateWishListItem
// ====================================================

export interface updateWishListItem_updateWishListItem {
  __typename: "WishListItem";
  id: number;
  wish_list_id: number | null;
  name: string | null;
  image: string | null;
  amount: number | null;
}

export interface updateWishListItem {
  updateWishListItem: updateWishListItem_updateWishListItem | null;
}

export interface updateWishListItemVariables {
  eventId: number;
  itemId: number;
  name: string;
  image: string;
  amount: number;
  currency?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteWishListItem
// ====================================================

export interface deleteWishListItem {
  deleteWishListItem: string;
}

export interface deleteWishListItemVariables {
  eventId: number;
  itemId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addMedia
// ====================================================

export interface addMedia_addMedia {
  __typename: "AlbumMedia";
  id: number;
  name: string | null;
  tags: (AlbumMediaTag | null)[];
  url: string | null;
}

export interface addMedia {
  addMedia: addMedia_addMedia;
}

export interface addMediaVariables {
  eventId: number;
  url: string;
  tags: AlbumMediaTag[];
  albumId?: number | null;
  name?: string | null;
  type?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAlbumMedia
// ====================================================

export interface deleteAlbumMedia {
  /**
   * Deprecated
   */
  deleteAlbumMedia: string | null;
}

export interface deleteAlbumMediaVariables {
  eventId: number;
  mediaId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteGuestCategory
// ====================================================

export interface deleteGuestCategory {
  deleteGuestCategory: string;
}

export interface deleteGuestCategoryVariables {
  event_id: number;
  categoryId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPaidWishListItems
// ====================================================

export interface getPaidWishListItems_getPaidWishListItems_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
}

export interface getPaidWishListItems_getPaidWishListItems {
  __typename: "WishListItem";
  id: number;
  event_id: number;
  name: string | null;
  image: string | null;
  amount: number | null;
  currency: string | null;
  user: getPaidWishListItems_getPaidWishListItems_user | null;
  appreciation: string | null;
}

export interface getPaidWishListItems {
  getPaidWishListItems:
    | (getPaidWishListItems_getPaidWishListItems | null)[]
    | null;
}

export interface getPaidWishListItemsVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: appreciateGift
// ====================================================

export interface appreciateGift_appreciateGift {
  __typename: "WishListItem";
  event_id: number;
  appreciation: string | null;
  id: number;
}

export interface appreciateGift {
  appreciateGift: appreciateGift_appreciateGift | null;
}

export interface appreciateGiftVariables {
  eventId: number;
  itemId: number;
  appreciation: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEvent
// ====================================================

export interface createEvent_createEvent_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
}

export interface createEvent_createEvent_event_owners {
  __typename: "EventOwner";
  id: number;
  user_id: number;
  user: createEvent_createEvent_event_owners_user | null;
  event_id: number;
  admin: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface createEvent_createEvent_event_type {
  __typename: "EventType";
  id: number;
  name: string;
  description: string;
}

export interface createEvent_createEvent {
  __typename: "Event";
  id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
  /**
   * deprecated
   */
  event_owners: createEvent_createEvent_event_owners[];
  event_type: createEvent_createEvent_event_type | null;
}

export interface createEvent {
  createEvent: createEvent_createEvent | null;
}

export interface createEventVariables {
  name: string;
  eventTypeId: number;
  role?: string | null;
  hashTag?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEvent
// ====================================================

export interface updateEvent_updateEvent {
  __typename: "Event";
  id: number;
  name: string;
  welcome_msg: string | null;
  hash_tag: string | null;
}

export interface updateEvent {
  updateEvent: updateEvent_updateEvent | null;
}

export interface updateEventVariables {
  eventId: number;
  name?: string | null;
  hashTag?: string | null;
  welcomeMsg?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEvent
// ====================================================

export interface deleteEvent {
  deleteEvent: string | null;
}

export interface deleteEventVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPassword
// ====================================================

export interface resetPassword {
  resetPassword: string;
}

export interface resetPasswordVariables {
  otp: string;
  password: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUserProfile
// ====================================================

export interface updateUserProfile_updateUserProfile {
  __typename: "User";
  id: number;
  title: string | null;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  other_name: string | null;
}

export interface updateUserProfile {
  updateUserProfile: updateUserProfile_updateUserProfile | null;
}

export interface updateUserProfileVariables {
  input: UpdateUserProfileRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInvitationCard
// ====================================================

export interface updateInvitationCard_updateInvitationCard {
  __typename: "InvitationCard";
  id: number | null;
  event_id: number | null;
  template: string | null;
  updated_at: any | null;
  created_at: any | null;
}

export interface updateInvitationCard {
  updateInvitationCard: updateInvitationCard_updateInvitationCard | null;
}

export interface updateInvitationCardVariables {
  eventId: number;
  template: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changePassword
// ====================================================

export interface changePassword {
  changePassword: string | null;
}

export interface changePasswordVariables {
  newPassword: string;
  oldPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventSlug
// ====================================================

export interface updateEventSlug_updateEventSlug {
  __typename: "Event";
  id: number;
  name: string;
  hash_tag: string | null;
  /**
   * deprecated
   */
  wallet_code: string | null;
  slug: string | null;
  welcome_msg: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface updateEventSlug {
  updateEventSlug: updateEventSlug_updateEventSlug | null;
}

export interface updateEventSlugVariables {
  eventId: number;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editEvent
// ====================================================

export interface editEvent_updateEventSlug {
  __typename: "Event";
  id: number;
  name: string;
  slug: string | null;
  welcome_msg: string | null;
  /**
   * deprecated
   */
  wallet_code: string | null;
}

export interface editEvent_updateEvent {
  __typename: "Event";
  id: number;
  name: string;
  hash_tag: string | null;
  welcome_msg: string | null;
}

export interface editEvent {
  updateEventSlug: editEvent_updateEventSlug | null;
  updateEvent: editEvent_updateEvent | null;
}

export interface editEventVariables {
  eventId: number;
  slug: string;
  updateEventEventId2: number;
  name?: string | null;
  hashTag?: string | null;
  welcomeMsg?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMessageById
// ====================================================

export interface deleteMessageById {
  deleteMessageById: string;
}

export interface deleteMessageByIdVariables {
  eventId: number;
  messageId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: transferToBank
// ====================================================

export interface transferToBank {
  transferToBank: boolean | null;
}

export interface transferToBankVariables {
  input?: transferFromWallet | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addEventTeamMember
// ====================================================

export interface addEventTeamMember {
  addEventTeamMember: boolean | null;
}

export interface addEventTeamMemberVariables {
  team: TeamMember;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeTeamMember
// ====================================================

export interface removeTeamMember {
  removeTeamMember: boolean | null;
}

export interface removeTeamMemberVariables {
  eventId: number;
  ownerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptEventTeam
// ====================================================

export interface acceptEventTeam_acceptEventTeam {
  __typename: "AcceptEventTeam";
  event_id: number;
  event_name: string;
  first_name: string;
  last_name: string;
  role: eventTeamRole;
}

export interface acceptEventTeam {
  acceptEventTeam: acceptEventTeam_acceptEventTeam | null;
}

export interface acceptEventTeamVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationListBySearch
// ====================================================

export interface invitationListBySearch_invitationListBySearch_category {
  __typename: "Category";
  id: number;
  name: string;
}

export interface invitationListBySearch_invitationListBySearch {
  __typename: "InvitationList";
  event_id: number;
  email: string | null;
  first_name: string;
  id: number;
  last_name: string;
  phone: string;
  category: invitationListBySearch_invitationListBySearch_category | null;
  rsvp_status: string | null;
  title: string | null;
}

export interface invitationListBySearch {
  invitationListBySearch: invitationListBySearch_invitationListBySearch[];
}

export interface invitationListBySearchVariables {
  eventId: number;
  searchTerm?: string | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventVendor
// ====================================================

export interface eventVendor_eventVendor {
  __typename: "EventVendor";
  id: number;
  event_id: number;
  instagram_handle: string | null;
  role: string | null;
  name: string | null;
  email: string | null;
  phone_number: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface eventVendor {
  eventVendor: (eventVendor_eventVendor | null)[];
}

export interface eventVendorVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMessageByUser
// ====================================================

export interface getMessageByUser_getMessageByUser_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
}

export interface getMessageByUser_getMessageByUser {
  __typename: "Message";
  id: number;
  content: string | null;
  status: boolean;
  event_id: number;
  updated_at: any | null;
  created_at: any | null;
  user: getMessageByUser_getMessageByUser_user | null;
}

export interface getMessageByUser {
  getMessageByUser: (getMessageByUser_getMessageByUser | null)[] | null;
}

export interface getMessageByUserVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: guestCategory
// ====================================================

export interface guestCategory_guestCategory {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface guestCategory {
  guestCategory: guestCategory_guestCategory[];
}

export interface guestCategoryVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: noGuestPerCategory
// ====================================================

export interface noGuestPerCategory_noGuestPerCategory {
  __typename: "GuestPerCategory";
  category_id: number;
  category_name: string;
  message: string | null;
  num_guest: number | null;
}

export interface noGuestPerCategory {
  noGuestPerCategory: (noGuestPerCategory_noGuestPerCategory | null)[];
}

export interface noGuestPerCategoryVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationList
// ====================================================

export interface invitationList_invitationList_category {
  __typename: "Category";
  id: number;
  name: string;
  event_id: number;
}

export interface invitationList_invitationList {
  __typename: "InvitationList";
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string | null;
  category: invitationList_invitationList_category | null;
}

export interface invitationList {
  invitationList: invitationList_invitationList[];
}

export interface invitationListVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBackStory
// ====================================================

export interface getBackStory_getBackStory {
  __typename: "BackStory";
  id: number;
  role: BackStoryRole;
  story: string;
  title: string | null;
  image: string | null;
  event_id: number;
}

export interface getBackStory {
  getBackStory: (getBackStory_getBackStory | null)[] | null;
}

export interface getBackStoryVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWishListItems
// ====================================================

export interface getWishListItems_getWishListItems {
  __typename: "WishListItem";
  id: number;
  wish_list_id: number | null;
  name: string | null;
  image: string | null;
  amount: number | null;
  currency: string | null;
  paid: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface getWishListItems {
  getWishListItems: (getWishListItems_getWishListItems | null)[] | null;
}

export interface getWishListItemsVariables {
  eventId: number;
  listId?: number | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationListByRSVPStatus
// ====================================================

export interface invitationListByRSVPStatus_invitationListByRSVPStatus_category {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface invitationListByRSVPStatus_invitationListByRSVPStatus {
  __typename: "InvitationList";
  id: number;
  event_id: number;
  first_name: string;
  last_name: string;
  phone: string;
  sent: boolean;
  email: string | null;
  rsvp_status: string | null;
  category: invitationListByRSVPStatus_invitationListByRSVPStatus_category | null;
}

export interface invitationListByRSVPStatus {
  invitationListByRSVPStatus: invitationListByRSVPStatus_invitationListByRSVPStatus[];
}

export interface invitationListByRSVPStatusVariables {
  eventId: number;
  rsvpStatus: RSVPStatus;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventParts
// ====================================================

export interface eventParts_eventParts {
  __typename: "EventPart";
  id: number;
  event_id: number;
  time: string;
  name: string;
  part: string;
  address: string;
  city: string | null;
  state: string | null;
  country: string | null;
  map_coordinate: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface eventParts {
  eventParts: eventParts_eventParts[];
}

export interface eventPartsVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAlbumDetails
// ====================================================

export interface getAlbumDetails_getAlbumDetails {
  __typename: "AlbumMedia";
  id: number;
  url: string | null;
  created_at: any | null;
}

export interface getAlbumDetails {
  getAlbumDetails: (getAlbumDetails_getAlbumDetails | null)[] | null;
}

export interface getAlbumDetailsVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
  albumId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventType
// ====================================================

export interface getEventType_getEventType {
  __typename: "EventType";
  id: number;
  name: string;
  description: string;
}

export interface getEventType {
  getEventType: getEventType_getEventType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventsForDashboard
// ====================================================

export interface getEventsForDashboard_events_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  id: number;
}

export interface getEventsForDashboard_events_event_owners {
  __typename: "EventOwner";
  id: number;
  user_id: number;
  user: getEventsForDashboard_events_event_owners_user | null;
  admin: boolean | null;
  role: string | null;
}

export interface getEventsForDashboard_events_event_wallet {
  __typename: "Wallet";
  /**
   * the uuid that identifies the wallet
   */
  id: string;
  name: string | null;
  /**
   * deprecated -> same as virtual_account_number
   */
  code: string | null;
  /**
   * calculated on call
   */
  balance: number;
  /**
   * currently only -> [ USD|NGN ]
   */
  currency: any;
  /**
   * a virtual bank account number associated with the wallet
   */
  virtual_account_number: string;
  /**
   * the bank providing the virtual bank account
   */
  virtual_bank_name: string;
}

export interface getEventsForDashboard_events {
  __typename: "Event";
  id: number;
  name: string;
  /**
   * deprecated
   */
  event_owners: getEventsForDashboard_events_event_owners[];
  slug: string | null;
  event_wallet: (getEventsForDashboard_events_event_wallet | null)[] | null;
}

export interface getEventsForDashboard_user {
  __typename: "User";
  id: number;
}

export interface getEventsForDashboard {
  events: (getEventsForDashboard_events | null)[];
  user: getEventsForDashboard_user | null;
}

export interface getEventsForDashboardVariables {
  isInvited?: boolean | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventByIdDashboardLayout
// ====================================================

export interface getEventByIdDashboardLayout_events_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  id: number;
}

export interface getEventByIdDashboardLayout_events_event_owners {
  __typename: "EventOwner";
  id: number;
  user: getEventByIdDashboardLayout_events_event_owners_user | null;
  admin: boolean | null;
  role: string | null;
}

export interface getEventByIdDashboardLayout_events {
  __typename: "Event";
  id: number;
  name: string;
  /**
   * deprecated
   */
  event_owners: getEventByIdDashboardLayout_events_event_owners[];
}

export interface getEventByIdDashboardLayout {
  events: (getEventByIdDashboardLayout_events | null)[];
}

export interface getEventByIdDashboardLayoutVariables {
  isInvited?: boolean | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: events
// ====================================================

export interface events_events_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
}

export interface events_events_event_owners {
  __typename: "EventOwner";
  id: number;
  user_id: number;
  user: events_events_event_owners_user | null;
  event_id: number;
  admin: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface events_events_event_wallet {
  __typename: "Wallet";
  /**
   * the uuid that identifies the wallet
   */
  id: string;
  /**
   * calculated on call
   */
  balance: number;
  /**
   * deprecated -> same as virtual_account_number
   */
  code: string | null;
  /**
   * the bank providing the virtual bank account
   */
  virtual_bank_name: string;
  /**
   * a virtual bank account number associated with the wallet
   */
  virtual_account_number: string;
  /**
   * currently only -> [ USD|NGN ]
   */
  currency: any;
}

export interface events_events_event_parts {
  __typename: "EventPart";
  id: number;
  event_id: number;
  time: string;
  address: string;
}

export interface events_events_event_type {
  __typename: "EventType";
  id: number;
  name: string;
  description: string;
}

export interface events_events_event_preference {
  __typename: "EventPreference";
  id: number;
  event_id: number;
  food: boolean | null;
  ads: boolean | null;
  asoebi_selection: boolean | null;
  gifts: boolean | null;
  store: boolean | null;
  vendor_store: boolean | null;
  reservation: boolean | null;
  dress_code: boolean | null;
  map: boolean | null;
  private: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface events_events {
  __typename: "Event";
  id: number;
  name: string;
  hash_tag: string | null;
  /**
   * deprecated
   */
  wallet_code: string | null;
  welcome_msg: string | null;
  slug: string | null;
  created_at: any | null;
  updated_at: any | null;
  /**
   * deprecated
   */
  event_owners: events_events_event_owners[];
  event_wallet: (events_events_event_wallet | null)[] | null;
  event_parts: events_events_event_parts[] | null;
  event_type: events_events_event_type | null;
  event_preference: events_events_event_preference | null;
}

export interface events {
  events: (events_events | null)[];
}

export interface eventsVariables {
  isInvited?: boolean | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationCard
// ====================================================

export interface invitationCard_invitationCard {
  __typename: "InvitationCard";
  id: number | null;
  event_id: number | null;
  template: string | null;
  updated_at: any | null;
  created_at: any | null;
}

export interface invitationCard {
  invitationCard: invitationCard_invitationCard | null;
}

export interface invitationCardVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: event
// ====================================================

export interface event_event {
  __typename: "Event";
  /**
   * deprecated
   */
  wallet_code: string | null;
}

export interface event {
  event: event_event | null;
}

export interface eventVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventPreference
// ====================================================

export interface getEventPreference_event_event_preference {
  __typename: "EventPreference";
  ads: boolean | null;
  asoebi_selection: boolean | null;
  created_at: any | null;
  dress_code: boolean | null;
  event_id: number;
  id: number;
  food: boolean | null;
  gifts: boolean | null;
  map: boolean | null;
  private: boolean | null;
  reservation: boolean | null;
  smart_send: boolean | null;
  store: boolean | null;
  updated_at: any | null;
  vendor_store: boolean | null;
}

export interface getEventPreference_event {
  __typename: "Event";
  event_preference: getEventPreference_event_event_preference | null;
}

export interface getEventPreference {
  event: getEventPreference_event | null;
}

export interface getEventPreferenceVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: user
// ====================================================

export interface user_user {
  __typename: "User";
  id: number;
  /**
   * gender of the person
   */
  gender: Gender | null;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  email: any | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  image: string | null;
}

export interface user {
  user: user_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUsername
// ====================================================

export interface getUsername_user {
  __typename: "User";
  id: number;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  image: string | null;
}

export interface getUsername {
  user: getUsername_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEventInfo
// ====================================================

export interface GetEventInfo_event {
  __typename: "Event";
  id: number;
  name: string;
  hash_tag: string | null;
  /**
   * deprecated
   */
  wallet_code: string | null;
  slug: string | null;
  welcome_msg: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface GetEventInfo {
  event: GetEventInfo_event | null;
}

export interface GetEventInfoVariables {
  eventEventId2: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBanksWithRave
// ====================================================

export interface getBanksWithRave_getBanksWithRave {
  __typename: "Bank";
  id: number | null;
  code: string | null;
  name: string | null;
}

export interface getBanksWithRave {
  getBanksWithRave: getBanksWithRave_getBanksWithRave[];
}

export interface getBanksWithRaveVariables {
  country: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: resolveAccountWithRave
// ====================================================

export interface resolveAccountWithRave_resolveAccountWithRave {
  __typename: "Account";
  accountName: string | null;
  accountNumber: string | null;
}

export interface resolveAccountWithRave {
  resolveAccountWithRave: resolveAccountWithRave_resolveAccountWithRave | null;
}

export interface resolveAccountWithRaveVariables {
  accountNumber: string;
  accountBank: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: refresh
// ====================================================

export interface refresh_refresh {
  __typename: "AuthResponse";
  refresh_token: string;
  access_token: string;
  access_expiry: any | null;
}

export interface refresh {
  /**
   * deprecated
   */
  refresh: refresh_refresh;
}

export interface refreshVariables {
  refresh_token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logout
// ====================================================

export interface logout {
  logout: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTeamMember
// ====================================================

export interface getTeamMember_getTeamMember_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  id: number;
  /**
   * phone number of the person
   */
  phone_number: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  verified: boolean;
  email: any | null;
  image: string | null;
}

export interface getTeamMember_getTeamMember {
  __typename: "EventOwner";
  event_id: number;
  user_id: number;
  user: getTeamMember_getTeamMember_user | null;
  admin: boolean | null;
  id: number;
  role: string | null;
}

export interface getTeamMember {
  getTeamMember: getTeamMember_getTeamMember[];
}

export interface getTeamMemberVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkEventSlug
// ====================================================

export interface checkEventSlug {
  checkEventSlug: boolean;
}

export interface checkEventSlugVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventPlan
// ====================================================

export interface getEventPlan_getEventPlan {
  __typename: "EventPlan";
  website_template: boolean;
  send_out_invites: boolean;
  upload_pictures: boolean;
  invitation_card: boolean;
  event_id: number;
  event_details: boolean;
  build_invite_list: boolean;
  categories: boolean;
  add_wishlist: boolean;
  add_event_vendors: boolean;
  add_back_story: boolean;
}

export interface getEventPlan {
  getEventPlan: getEventPlan_getEventPlan;
}

export interface getEventPlanVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationListByCategory
// ====================================================

export interface invitationListByCategory_invitationListByCategory {
  __typename: "InvitationList";
  id: number;
  first_name: string;
  last_name: string;
  category_id: number | null;
}

export interface invitationListByCategory {
  invitationListByCategory: invitationListByCategory_invitationListByCategory[];
}

export interface invitationListByCategoryVariables {
  eventId: number;
  categoryId?: number | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCurrencies
// ====================================================

export interface getCurrencies_currencyV1 {
  __typename: "AvailableCurrency";
  /**
   * e.g Dollar, Naira
   */
  name: string;
  /**
   * e.g $, ₦
   */
  symbol: string | null;
  /**
   * e.g USD, NGN
   */
  currency: any;
}

export interface getCurrencies {
  /**
   * returns a list of available currencies with thier name, symbol and slug(currency)
   *     @isAuthenticated
   */
  currencyV1: (getCurrencies_currencyV1 | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventWallets
// ====================================================

export interface getEventWallets_getWalletsV1_transaction_history_user_user_details {
  __typename: "UserDetails";
  id: number;
  user_id: number;
  image: string | null;
  age: number | null;
  languages: (string | null)[];
  address: string | null;
  city: string | null;
  state: string | null;
  Country: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface getEventWallets_getWalletsV1_transaction_history_user {
  __typename: "User";
  id: number;
  uuid: any | null;
  email: any | null;
  /**
   * first name of the person
   */
  first_name: string;
  image: string | null;
  /**
   * last name of the person
   */
  last_name: string | null;
  other_name: string | null;
  title: string | null;
  /**
   * gender of the person
   */
  gender: Gender | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  verified: boolean;
  guest: boolean | null;
  created_at: any | null;
  updated_at: any | null;
  lastLoggedInAt: any | null;
  user_details: getEventWallets_getWalletsV1_transaction_history_user_user_details | null;
}

export interface getEventWallets_getWalletsV1_transaction_history {
  __typename: "TransactionV1";
  /**
   * used for indexing
   */
  id: string;
  /**
   * reference -> very important (takes precedence over the id)
   */
  reference: string;
  /**
   * e.g paystack, monnify, flutterwave ... (all lower case)
   */
  payment_processor: string;
  /**
   * provides context of transaction
   */
  description: string;
  /**
   * the id of the event for which the transaction was made
   */
  event_uuid: any;
  /**
   * if the transaction was made by an authenticated user
   */
  user_uuid: any | null;
  /**
   * anonymous or the person's name
   */
  full_name: string | null;
  /**
   * returns if the transaction was made by an authorized user
   */
  user: getEventWallets_getWalletsV1_transaction_history_user | null;
  /**
   * a uuid string representing the wallet id
   */
  wallet_id: any;
  /**
   * deprecated -> same as virtual bank account number
   */
  wallet_code: string | null;
  /**
   * for now [ USD | NGN ] ...all caps (upper case)
   */
  currency: any;
  /**
   * in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200
   */
  amount: number;
  /**
   * true|false (a summary of the stage of the transaction) ==>
   *
   * stage:successful-> true
   * &
   * stage:failed -> false
   */
  status: string;
  /**
   * [ successful | failed | pending ] ... (all lower case)
   */
  stage: string;
  /**
   * [ credit | debit ] ... (all lower case)
   */
  type: string;
  /**
   * only provided if the transaction was for payment of a wishlist item
   */
  item_id: string | null;
  /**
   * time the transaction was done
   */
  created_at: any | null;
  /**
   * last update at
   */
  updated_at: any | null;
}

export interface getEventWallets_getWalletsV1 {
  __typename: "Wallet";
  /**
   * the uuid that identifies the wallet
   */
  id: string;
  name: string | null;
  /**
   * deprecated -> same as virtual_account_number
   */
  code: string | null;
  /**
   * calculated on call
   */
  balance: number;
  /**
   * currently only -> [ USD|NGN ]
   */
  currency: any;
  /**
   * a virtual bank account number associated with the wallet
   */
  virtual_account_number: string;
  /**
   * the bank providing the virtual bank account
   */
  virtual_bank_name: string;
  /**
   * history of transactions done on the wallet
   */
  transaction_history:
    | (getEventWallets_getWalletsV1_transaction_history | null)[]
    | null;
  /**
   * time when the wallet was created
   */
  created_at: any | null;
  /**
   * last updated at
   */
  updated_at: any | null;
}

export interface getEventWallets {
  /**
   * returns all wallet belonging to an event
   */
  getWalletsV1: (getEventWallets_getWalletsV1 | null)[];
}

export interface getEventWalletsVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWalletTransactionHistory
// ====================================================

export interface getWalletTransactionHistory_getWalletTransactionHistoryV1_user {
  __typename: "User";
  id: number;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  guest: boolean | null;
}

export interface getWalletTransactionHistory_getWalletTransactionHistoryV1 {
  __typename: "TransactionV1";
  /**
   * used for indexing
   */
  id: string;
  /**
   * provides context of transaction
   */
  description: string;
  /**
   * the id of the event for which the transaction was made
   */
  event_uuid: any;
  /**
   * returns if the transaction was made by an authorized user
   */
  user: getWalletTransactionHistory_getWalletTransactionHistoryV1_user | null;
  /**
   * a uuid string representing the wallet id
   */
  wallet_id: any;
  /**
   * deprecated -> same as virtual bank account number
   */
  wallet_code: string | null;
  /**
   * for now [ USD | NGN ] ...all caps (upper case)
   */
  currency: any;
  /**
   * in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200
   */
  amount: number;
  /**
   * only provided if the transaction was for payment of a wishlist item
   */
  item_id: string | null;
  /**
   * [ credit | debit ] ... (all lower case)
   */
  type: string;
  /**
   * [ successful | failed | pending ] ... (all lower case)
   */
  stage: string;
}

export interface getWalletTransactionHistory {
  /**
   * returns all wallet transaction history.
   *     It is paginated and shows the most recent history
   *     @isAuthenticated
   */
  getWalletTransactionHistoryV1: getWalletTransactionHistory_getWalletTransactionHistoryV1[];
}

export interface getWalletTransactionHistoryVariables {
  walletId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: transferToBankV2
// ====================================================

export interface transferToBankV2_transferToBankV2_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  id: number;
  /**
   * last name of the person
   */
  last_name: string | null;
  email: any | null;
}

export interface transferToBankV2_transferToBankV2 {
  __typename: "TransactionV1";
  /**
   * in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200
   */
  amount: number;
  /**
   * used for indexing
   */
  id: string;
  /**
   * the id of the event for which the transaction was made
   */
  event_uuid: any;
  /**
   * for now [ USD | NGN ] ...all caps (upper case)
   */
  currency: any;
  /**
   * deprecated -> same as virtual bank account number
   */
  wallet_code: string | null;
  /**
   * returns if the transaction was made by an authorized user
   */
  user: transferToBankV2_transferToBankV2_user | null;
}

export interface transferToBankV2 {
  /**
   * called when is a user wants to withdraw money to a bank
   */
  transferToBankV2: transferToBankV2_transferToBankV2;
}

export interface transferToBankV2Variables {
  payload: transferToBankRequestV2;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlbumMediaTag {
  best_man = "best_man",
  bride = "bride",
  bridesmaid = "bridesmaid",
  family = "family",
  friends = "friends",
  groom = "groom",
  hero_page = "hero_page",
  others = "others",
}

export enum BackStoryRole {
  Bride = "Bride",
  Groom = "Groom",
}

export enum Gender {
  female = "female",
  male = "male",
}

export enum RSVPStatus {
  maybe = "maybe",
  no = "no",
  pending = "pending",
  yes = "yes",
}

export enum eventTeamRole {
  bride = "bride",
  groom = "groom",
  planner = "planner",
}

export interface AddEventPartRequest {
  event_id: number;
  time: string;
  name: string;
  welcome_msg?: string | null;
  address: string;
  part: string;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  map_coordinate?: string | null;
}

export interface AddEventVendorRequest {
  event_id: number;
  name?: string | null;
  role: string;
  email?: string | null;
  phone_number: string;
  instagram_handle?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  image?: string | null;
}

export interface AddToInviteListReq {
  id?: number | null;
  first_name: string;
  last_name: string;
  other_name?: string | null;
  title?: string | null;
  phone: string;
  category_id?: number | null;
  email?: string | null;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface SignUpRequest {
  phone_number: string;
  email?: any | null;
  password: any;
  first_name: string;
  last_name: string;
  other_name?: string | null;
  title?: string | null;
  gender?: Gender | null;
}

export interface TeamMember {
  event_id: number;
  first_name: string;
  last_name: string;
  other_name?: string | null;
  title?: string | null;
  email?: string | null;
  phone_number: string;
  role: eventTeamRole;
}

export interface UpdateEventPartRequest {
  id: number;
  event_id: number;
  time?: string | null;
  name?: string | null;
  part?: string | null;
  welcome_msg?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  map_coordinate?: string | null;
}

export interface UpdateEventPreferenceRequest {
  id?: number | null;
  event_id: number;
  food?: boolean | null;
  ads?: boolean | null;
  asoebi_selection?: boolean | null;
  gifts?: boolean | null;
  store?: boolean | null;
  vendor_store?: boolean | null;
  reservation?: boolean | null;
  smart_send?: boolean | null;
  dress_code?: boolean | null;
  map?: boolean | null;
  private?: boolean | null;
}

export interface UpdateEventVendorRequest {
  id: number;
  event_id: number;
  name?: string | null;
  role?: string | null;
  image?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  email?: string | null;
  instagram_handle?: string | null;
  phone_number?: string | null;
}

export interface UpdateInviteListReq {
  id: number;
  first_name?: string | null;
  last_name?: string | null;
  other_name?: string | null;
  title?: string | null;
  phone?: string | null;
  category_id?: number | null;
  email?: string | null;
}

export interface UpdateUserProfileRequest {
  image?: string | null;
  first_name?: string | null;
  other_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
}

export interface addBackStoryReq {
  title?: string | null;
  image?: string | null;
  role: BackStoryRole;
  story: string;
}

export interface transferFromWallet {
  currency: any;
  eventId: number;
  walletId: string;
  amount: number;
  accountNumber: string;
  accountBank: string | null;
  narration?: string | null;
  bankCode: string | null;
  reference: string;
}

export interface transferToBankRequestV2 {
  currency: any;
  eventId: number;
  walletId: string;
  amount: number;
  recipient_code: string;
  reason?: string | null;
}

export interface updateBackStoryReq {
  id: number;
  role?: BackStoryRole | null;
  title?: string | null;
  image?: string | null;
  story?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
