import { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./messages.module.scss";
import MessagesTable from "./MessagesTable";
// import SendBroadcast from "./SendBroadcast";
import { useQuery } from "@apollo/client";
import { GetMessage } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { getMessageByUser } from "src/types/api";
import EmptyState from "./EmptyState";
import { useNavigate } from "react-router-dom";

const Messages = () => {
  // const [openBroadcast, setOpenBroadcast] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [pageSize, setPageSize] = useState(300);
  const [page, setPage] = useState(1);
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { data: messages, loading: getMessageLoading } =
    useQuery<getMessageByUser>(GetMessage, {
      variables: {
        eventId: eventId,
        page: page,
        pageSize: pageSize,
      },
      fetchPolicy: "cache-and-network",
      onCompleted(response) {
        if (!!response?.getMessageByUser && response?.getMessageByUser.length) {
          setTotalPages(
            Math.floor(response?.getMessageByUser.length / pageSize)
          );
        }
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  return (
    <div className={styles["messages"]}>
      <div className={styles["messages__button-container"]}>
        {/* <div className={styles["messages__button"]}>
          <DialogModal
            open={openBroadcast}
            onOpenChange={setOpenBroadcast}
            trigger={
              <div
                onClick={() => setOpenBroadcast(true)}
                className={styles["messages__trigger"]}
              >
                <span>Send Broadcast</span>
              </div>
            }
          >
            <SendBroadcast setToggleModal={setOpenBroadcast} />
          </DialogModal>
        </div> */}
      </div>
      <div className={styles["messages__table"]}>
        <div className={styles["messages__table-main"]}>
          {getMessageLoading ? (
            <div className={styles["messages__loading"]}>
              <InnerLoading />
            </div>
          ) : !!messages?.getMessageByUser &&
            messages?.getMessageByUser.length > 0 ? (
            messages?.getMessageByUser?.map((element) => {
              return (
                <MessagesTable
                  page={page}
                  pageSize={pageSize}
                  key={uuidv4()}
                  message={element}
                />
              );
            })
          ) : (
            <EmptyState />
          )}
        </div>

        <div style={{ display: "none" }}>
          {!getMessageLoading && messages?.getMessageByUser && (
            <Pagination
              pageSize={pageSize}
              startPage={startPage}
              setPageSize={setPageSize}
              setStartPage={setStartPage}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
