import Button from "src/components/Button";
import styles from "./giftTransferTable.module.scss";
import Dialog from "src/components/Dialog";
import AppreciateGift, { AppreciateGiftsProps } from "./AppreciateGift";
import { useState } from "react";
// import { appreciateGiftVariables } from "src/types/api";

interface GiftTransferTableProps {
  giftsReceived?: string;
  amount?: number;
  firstName: string;
  lastName: string;
  id: number;
  loading: boolean;
  appreciateGift: (arg: AppreciateGiftsProps) => void;
}

const GiftTransferTable: React.FC<GiftTransferTableProps> = ({
  giftsReceived,
  amount,
  firstName,
  lastName,
  loading,
  id,
  appreciateGift,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles["table__list-item"]}>
      <div className={styles["table__list-item__left"]}>
        <span className={styles["table__name"]}>
          {firstName} {lastName}
        </span>{" "}
        sent you <span className={styles["table__amount"]}>NGN {amount}</span>{" "}
        for{" "}
        <span className={styles["table__giftsReceived"]}>{giftsReceived}</span>
      </div>

      <div>
        <Dialog
          open={openModal}
          onOpenChange={setOpenModal}
          trigger={
            <div className={styles["table__action"]}>
              <Button
                label="Appreciate Gift"
                type="button"
                design="secondary"
                handleClick={() => null}
                disabled={false}
                loader={loading}
              >
                <span>Appreciate Gift</span>
              </Button>
            </div>
          }
        >
          <div className={styles["dialog-content"]}>
            <AppreciateGift
              setToggleModal={() => setOpenModal((prev) => !prev)}
              appreciateGift={appreciateGift}
              loading={loading}
              id={id}
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default GiftTransferTable;
