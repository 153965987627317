import React, { useState } from "react";
import styles from "./inviteListCard.module.scss";
import Icon from "src/components/Icon";
import {
  invitationList_invitationList,
  noGuestPerCategory,
} from "src/types/api";
import * as Popover from "@radix-ui/react-popover";
import DialogModal from "src/components/Dialog";
import EditGuest from "../modals/EditGuest";
import RemoveGuest from "../modals/RemoveGuest";

interface InviteListCardProps {
  categories: noGuestPerCategory | undefined;
  invitee: invitationList_invitationList;
  page: number;
  pageSize: number;
}

const InviteListCard: React.FC<InviteListCardProps> = ({
  categories,
  invitee,
  page,
  pageSize,
}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <div className={styles["invite-list-card"]}>
      <p>
        {invitee.first_name} {invitee.last_name}
      </p>
      <p>{invitee?.email ?? "None"}</p>
      <p>{invitee?.phone}</p>
      <p>{invitee?.category?.name ?? "None"}</p>
      <div className={styles["invite-list-card__dropdown-container"]}>
        <Popover.Root>
          <Popover.Trigger
            className={styles["invite-list-card__dropdown-container-button"]}
          >
            <Icon iconName="kebab" />
            <span className="visually-hidden">open dropdown</span>
          </Popover.Trigger>
          <Popover.Content className={styles["invite-list-card__dropdown"]}>
            <div className={styles["invite-list-card__dropdown-list"]}>
              {!!invitee && (
                <>
                  <DialogModal
                    trigger={
                      <div className={styles["invite-list-card__edit"]}>
                        Edit Guest
                      </div>
                    }
                    open={openEdit}
                    onOpenChange={setOpenEdit}
                  >
                    <EditGuest
                      categories={categories}
                      invitee={invitee}
                      setOpen={setOpenEdit}
                      page={page}
                      pageSize={pageSize}
                    />
                  </DialogModal>
                  <DialogModal
                    trigger={
                      <div className={styles["invite-list-card__remove"]}>
                        Delete Guest
                      </div>
                    }
                    open={openDelete}
                    onOpenChange={setOpenDelete}
                  >
                    <RemoveGuest
                      invitee={invitee}
                      setOpen={setOpenEdit}
                      page={page}
                      pageSize={pageSize}
                    />
                  </DialogModal>
                </>
              )}
            </div>
          </Popover.Content>
        </Popover.Root>
      </div>
    </div>
  );
};

export default InviteListCard;
