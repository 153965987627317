import { Spinner } from "../Spinner";
import styles from "./button.module.scss";

interface ButtonProps {
  children: React.ReactNode;
  label: string;
  handleClick?: () => void;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  loader?: boolean;
  design?: "primary" | "secondary" | "tertiary";
}

const Button: React.FC<ButtonProps> = ({
  children,
  type,
  label,
  handleClick,
  disabled,
  loader,
  design = "primary",
}) => {
  return (
    <button
      onClick={handleClick}
      aria-label={label}
      type={type}
      className={`${design === "primary" && styles["button"]} ${
        design === "secondary" && styles["button--secondary"]
      }`}
      disabled={disabled}
    >
      <span>{loader ? <Spinner /> : children}</span>
    </button>
  );
};

export default Button;
