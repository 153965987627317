import styles from "./viewMessage.module.scss";
import { getMessageByUser_getMessageByUser } from "src/types/api";
import DialogModal from "src/components/Dialog";
import SendReply from "../SendReply";
import { Dispatch, SetStateAction } from "react";

interface ViewMessageProps {
  setOpenViewMessage: Dispatch<SetStateAction<boolean>>;
  setOpenSendReply: Dispatch<SetStateAction<boolean>>;
  openSendReply: boolean;
  message: getMessageByUser_getMessageByUser | null;
}

const ViewMessage: React.FC<ViewMessageProps> = ({
  message,
  openSendReply,
  setOpenSendReply,
  setOpenViewMessage,
}) => {
  return (
    <div className={styles["viewMessage"]}>
      <div className={styles["viewMessage-heading"]}>
        <h4>View Message</h4>
      </div>
      <div className={styles["viewMessage-card"]}>
        <p>{message?.content}</p>
      </div>
      <div className={styles["viewMessage-button-container"]}>
        <div className={styles["viewMessage-button"]}>
          <DialogModal
            open={openSendReply}
            onOpenChange={setOpenSendReply}
            trigger={
              <div
                onClick={() => {
                  setOpenViewMessage(false);
                  setOpenSendReply(true);
                }}
                className={styles["viewMessage-button__trigger"]}
              >
                <span>Reply</span>
              </div>
            }
          >
            <SendReply setToggleModal={setOpenSendReply} message={message} />
          </DialogModal>
        </div>
      </div>
    </div>
  );
};

export default ViewMessage;
