import { useState } from "react";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import UploadCard from "../UploadCard";
import styles from "./templateCard.module.scss";

interface TemplateCardProps {
  title: string;
  image: string;
}

type OrientationType = "portrait" | "landscape";

const TemplateCard: React.FC<TemplateCardProps> = ({ title, image }) => {
  const [orientation] = useState<OrientationType>("landscape");
  const [show, setShow] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  return (
    <div className={styles["templateCard"]}>
      <div className={styles["templateCard__container"]}>
        <div className={styles["templateCard__image"]}>
          {image && <img src={image} alt="template" />}
        </div>
        <h3 className={styles["templateCard__heading"]}>{title}</h3>
        <div className={styles["templateCard__icon"]}>
          <Icon iconName="templateArrowRight" />
        </div>
        <div className={styles["templateCard__preview"]}>
          {!!image && (
            <DialogModal
              open={show}
              onOpenChange={setShow}
              trigger={
                <div className={styles["templateCard__preview-button"]}>
                  <p>Preview</p>
                  <span>
                    <Icon iconName="preview" />
                  </span>
                </div>
              }
            >
              <div className={styles["templateCard__mobile-preview"]}>
                <div className={styles["templateCard__mobile-preview-title"]}>
                  {/* <h2>Preview</h2> */}

                  <div className={styles["templateCard__mobile-views"]}>
                    {/* <div className={styles["templateCard__mobile-views-box"]}>
                      <button
                        className={`${
                          styles["templateCard__mobile-views-orientation"]
                        }
                        ${
                          orientation === "landscape" &&
                          styles[
                            "templateCard__mobile-views-orientation__active"
                          ]
                        }`}
                        onClick={() => setOrientation("landscape")}
                      >
                        <Icon iconName="landscape" />
                      </button>
                      <button
                        className={`${
                          styles["templateCard__mobile-views-orientation"]
                        }
                        ${
                          orientation === "portrait" &&
                          styles[
                            "templateCard__mobile-views-orientation__active"
                          ]
                        }`}
                        onClick={() => setOrientation("portrait")}
                      >
                        <Icon iconName="potrait" />
                      </button>
                    </div> */}
                  </div>
                </div>

                <div
                  className={
                    styles["templateCard__mobile-preview-box-container"]
                  }
                >
                  <div
                    className={`${styles["templateCard__mobile-preview-box"]}
                  ${
                    orientation === "landscape" &&
                    styles["templateCard__mobile-preview-box__landscape"]
                  }
                  ${
                    orientation === "portrait" &&
                    styles["templateCard__mobile-preview-box__portrait"]
                  }
                  `}
                  >
                    {image && <img src={image} alt="template" />}
                  </div>
                </div>
              </div>
            </DialogModal>
          )}
          <DialogModal
            trigger={
              <div className={styles["templateCard__preview-buttons"]}>
                <span>{image ? "Change IV" : "Upload IV"}</span>
              </div>
            }
            open={openUpload}
            onOpenChange={setOpenUpload}
          >
            <UploadCard setToggleModal={setOpenUpload} />
          </DialogModal>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
