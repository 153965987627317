import { ReactComponent as ArrowLeft } from "../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/arrowRight.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as EyeClose } from "../assets/icons/eyeClose.svg";
import { ReactComponent as Toggle } from "../assets/icons/toggle.svg";
import { ReactComponent as Close2 } from "../assets/icons/close2.svg";
import { ReactComponent as Close3 } from "../assets/icons/close3.svg";
import { ReactComponent as Hamburger } from "../assets/icons/hamburger.svg";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";
import { ReactComponent as ArrowRightBlack } from "../assets/icons/arrowRightBlack.svg";
import { ReactComponent as CaretDown } from "../assets/icons/caretDown.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import { ReactComponent as CaretDouble } from "../assets/icons/caretDouble.svg";
import { ReactComponent as CaretDoubleBlack } from "../assets/icons/caretDoubleBlack.svg";
import { ReactComponent as Kebab } from "../assets/icons/kebab.svg";
import { ReactComponent as Link } from "../assets/icons/link.svg";
import { ReactComponent as LinkOut } from "../assets/icons/linkOut.svg";
import { ReactComponent as RedArrowLeft } from "../assets/icons/redArrowLeft.svg";
import { ReactComponent as Tick } from "../assets/icons/tick.svg";
import { ReactComponent as ArrowLeftBlack } from "../assets/icons/arrowLeftBlack.svg";
import { ReactComponent as Polygon } from "../assets/icons/Polygon.svg";
import { ReactComponent as Dashboard } from "../assets/icons/dashboard.svg";
import { ReactComponent as EventDetails } from "../assets/icons/eventDetails.svg";
import { ReactComponent as EventTeam } from "../assets/icons/eventTeam.svg";
import { ReactComponent as Gifts } from "../assets/icons/gifts.svg";
import { ReactComponent as InvitationCard } from "../assets/icons/invitationCard.svg";
import { ReactComponent as InviteList } from "../assets/icons/inviteList.svg";
import { ReactComponent as Messages } from "../assets/icons/messages.svg";
import { ReactComponent as Website } from "../assets/icons/website.svg";
import { ReactComponent as TemplateArrowRight } from "../assets/icons/templateArrowRight.svg";
import { ReactComponent as Landscape } from "../assets/icons/landscape.svg";
import { ReactComponent as Potrait } from "../assets/icons/potrait.svg";
import { ReactComponent as Warning } from "../assets/icons/warning.svg";
import { ReactComponent as CopyText } from "../assets/icons/copyText.svg";
import { ReactComponent as Bulb } from "../assets/icons/bulb.svg";
import { ReactComponent as Checked } from "../assets/icons/Checked.svg";
import { ReactComponent as RightArrow } from "../assets/icons/rightArrow.svg";
import { ReactComponent as EyeOpen } from "../assets/icons/eyeOpen.svg";
import { ReactComponent as Option } from "../assets/icons/Option.svg";
import { ReactComponent as SuccessCheck } from "../assets/icons/SuccessCheck.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/leftIcon.svg";
import { ReactComponent as InputIcon } from "../assets/icons/inputIcon.svg";
import { ReactComponent as Preview } from "../assets/icons/preview.svg";
import { ReactComponent as ShortRightArrow } from "../assets/icons/shortRightArrow.svg";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import { ReactComponent as Clock } from "../assets/icons/clock.svg";
import { ReactComponent as Location } from "../assets/icons/location.svg";
import { ReactComponent as Sad } from "../assets/icons/sad.svg";
import { ReactComponent as WalletEmpty } from "../assets/icons/walletEmpty.svg";
import { ReactComponent as Send } from "../assets/icons/send.svg";
import { ReactComponent as Withdraw } from "../assets/icons/withdraw.svg";
import { ReactComponent as Fund } from "../assets/icons/fund.svg";
import { ReactComponent as Unlink } from "../assets/icons/unlink.svg";
import { ReactComponent as NotFound } from "../assets/icons/notFound.svg";
import { ReactComponent as ComingSoon } from "../assets/icons/comingSoon.svg";
import { ReactComponent as Empty } from "../assets/icons/empty.svg";
import { ReactComponent as FirstAnkara } from "../assets/icons/ankara1.svg";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { ReactComponent as LogoWhite } from "../assets/icons/logowhite.svg";
import { ReactComponent as Tick2 } from "../assets/icons/tick2.svg";
import { ReactComponent as Previous } from "../assets/icons/previous.svg";
import { ReactComponent as Next } from "../assets/icons/next.svg";
import { ReactComponent as PreviewEye } from "../assets/icons/previewEye.svg";
import { ReactComponent as Parts } from "../assets/images/parts.svg";
import { ReactComponent as Vendors } from "../assets/images/vendor.svg";
import { ReactComponent as Event } from "../assets/images/event.svg";
import { ReactComponent as Gift } from "../assets/images/gift.svg";
import { ReactComponent as Gift2 } from "../assets/images/gift2.svg";
import { ReactComponent as Category } from "../assets/images/category.svg";
import { ReactComponent as Cards } from "../assets/images/cards.svg";
import { ReactComponent as Rsvp } from "../assets/images/rsvp.svg";
import { ReactComponent as Backstory } from "../assets/images/backstory.svg";
import { ReactComponent as Post } from "../assets/images/posts.svg";
import { ReactComponent as Photobook } from "../assets/images/photobook.svg";
import { ReactComponent as DeleteFilled } from "../assets/icons/deleteFilled.svg";
import { ReactComponent as Congrats } from "../assets/icons/congrats.svg";

export type IconType =
  | "arrowLeft"
  | "arrowRight"
  | "arrowRightBlack"
  | "arrowLeftBlack"
  | "close"
  | "eyeClose"
  | "toggle"
  | "close2"
  | "previewEye"
  | "hamburger"
  | "copy"
  | "caretDown"
  | "search"
  | "kebab"
  | "link"
  | "tick"
  | "polygon"
  | "deleteFilled"
  | "dashboard"
  | "inviteList"
  | "eventDetails"
  | "congrats"
  | "invitationCard"
  | "gifts"
  | "eventTeam"
  | "messages"
  | "website"
  | "polygon"
  | "checked"
  | "templateArrowRight"
  | "potrait"
  | "landscape"
  | "bulb"
  | "warning"
  | "copyText"
  | "bulb"
  | "polygon"
  | "checked"
  | "rightArrow"
  | "eyeOpen"
  | "option"
  | "successCheck"
  | "leftArrow"
  | "inputIcon"
  | "preview"
  | "shortRightArrow"
  | "calendar"
  | "clock"
  | "location"
  | "sad"
  | "walletEmpty"
  | "send"
  | "withdraw"
  | "fund"
  | "unlink"
  | "notFound"
  | "comingSoon"
  | "empty"
  | "firstAnkara"
  | "logo"
  | "logowhite"
  | "caretDouble"
  | "caretDoubleBlack"
  | "linkOut"
  | "tick2"
  | "previous"
  | "next"
  | "redArrowLeft"
  | "parts"
  | "event"
  | "gift"
  | "gift2"
  | "close3"
  | "rsvp"
  | "cards"
  | "category"
  | "post"
  | "backstory"
  | "photobook"
  | "vendor";

export const icons: Record<IconType, JSX.Element> = {
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  arrowRightBlack: <ArrowRightBlack />,
  arrowLeftBlack: <ArrowLeftBlack />,
  close: <Close />,
  eyeClose: <EyeClose />,
  deleteFilled: <DeleteFilled />,
  toggle: <Toggle />,
  close2: <Close2 />,
  close3: <Close3 />,
  congrats: <Congrats />,
  hamburger: <Hamburger />,
  copy: <Copy />,
  caretDown: <CaretDown />,
  search: <Search />,
  kebab: <Kebab />,
  link: <Link />,
  linkOut: <LinkOut />,
  tick: <Tick />,
  polygon: <Polygon />,
  dashboard: <Dashboard />,
  inviteList: <InviteList />,
  eventDetails: <EventDetails />,
  invitationCard: <InvitationCard />,
  gifts: <Gifts />,
  eventTeam: <EventTeam />,
  messages: <Messages />,
  website: <Website />,
  checked: <Checked />,
  templateArrowRight: <TemplateArrowRight />,
  potrait: <Potrait />,
  landscape: <Landscape />,
  bulb: <Bulb />,
  warning: <Warning />,
  copyText: <CopyText />,
  previewEye: <PreviewEye />,
  rightArrow: <RightArrow />,
  eyeOpen: <EyeOpen />,
  option: <Option />,
  tick2: <Tick2 />,
  successCheck: <SuccessCheck />,
  leftArrow: <LeftArrow />,
  inputIcon: <InputIcon />,
  preview: <Preview />,
  shortRightArrow: <ShortRightArrow />,
  calendar: <Calendar />,
  location: <Location />,
  clock: <Clock />,
  sad: <Sad />,
  walletEmpty: <WalletEmpty />,
  send: <Send />,
  withdraw: <Withdraw />,
  fund: <Fund />,
  unlink: <Unlink />,
  notFound: <NotFound />,
  comingSoon: <ComingSoon />,
  empty: <Empty />,
  firstAnkara: <FirstAnkara />,
  logo: <Logo />,
  logowhite: <LogoWhite />,
  caretDouble: <CaretDouble />,
  caretDoubleBlack: <CaretDoubleBlack />,
  redArrowLeft: <RedArrowLeft />,
  previous: <Previous />,
  next: <Next />,
  parts: <Parts />,
  vendor: <Vendors />,
  event: <Event />,
  gift: <Gift />,
  gift2: <Gift2 />,
  category: <Category />,
  rsvp: <Rsvp />,
  cards: <Cards />,
  backstory: <Backstory />,
  post: <Post />,
  photobook: <Photobook />,
};
