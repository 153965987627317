import styles from "./sendReply.module.scss";
import { Formik, Form, FormikProps } from "formik";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import * as Yup from "yup";
import { ReplyMessage } from "src/graphql/mutations";
import { useMutation } from "@apollo/client";
import { GetMessage } from "src/graphql/queries";
import { useContext } from "react";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import {
  getMessageByUser_getMessageByUser,
  replyMessage,
  replyMessageVariables,
} from "src/types/api";
import { useNavigate } from "react-router-dom";

export interface Values {
  reply: string;
}

const SendReplySchema = Yup.object().shape({
  reply: Yup.string(),
});

interface SendReplyProps {
  setToggleModal: (toggleModal: boolean) => void;
  message?: getMessageByUser_getMessageByUser | null;
}

const SendReply: React.FC<SendReplyProps> = ({ setToggleModal, message }) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const [replyMessage, { loading }] = useMutation<
    replyMessage,
    replyMessageVariables
  >(ReplyMessage, {
    onCompleted() {
      setToggleModal(false);
      toast.success(
        <p className="toast">You have successfully replied a message.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["send__reply"]}>
      <div className={styles["send__reply-heading"]}>
        <h4>Send a Reply</h4>
      </div>
      <div className={styles["send__reply-card"]}>
        <p>{message?.content}</p>
      </div>
      <Formik
        initialValues={{
          reply: "",
        }}
        validationSchema={SendReplySchema}
        onSubmit={(values, actions) => {
          // actions.resetForm();
          eventId &&
            message?.id &&
            replyMessage({
              variables: {
                eventId: eventId,
                messageId: message?.id,
                reply: values.reply,
              },
              refetchQueries: [
                {
                  query: GetMessage,
                  variables: { eventId: eventId },
                },
              ],
            });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["send__reply-form"]}>
            <div className={styles["send__reply-form-container"]}>
              <Input
                name="reply"
                label=""
                type="text"
                placeholder="Enter a reply"
              />
            </div>
            <div className={styles["send__reply-button-container"]}>
              <div className={styles["send__reply-button"]}>
                <Button
                  type="submit"
                  label="Send Reply"
                  disabled={props.values.reply === ""}
                  loader={loading}
                >
                  Send Reply
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SendReply;
