import { gql } from "@apollo/client";

export const AddEventTeamMember = gql`
  mutation addEventTeamMember($team: TeamMember!) {
    addEventTeamMember(team: $team)
  }
`;

export const RemoveTeamMember = gql`
  mutation removeTeamMember($eventId: Int!, $ownerId: Int!) {
    removeTeamMember(event_id: $eventId, owner_id: $ownerId)
  }
`;

export const AcceptEventTeam = gql`
  mutation acceptEventTeam($token: String!) {
    acceptEventTeam(token: $token) {
      event_id
      event_name
      first_name
      last_name
      role
    }
  }
`;
