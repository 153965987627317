import { useQuery, useMutation } from "@apollo/client";
import { useContext } from "react";
import {
  CreateAppreciation,
  GetPaidWishListItems,
} from "src/graphql/mutations";
import styles from "./giftTransfer.module.scss";
import GiftTransferTable from "./GiftTransferTable";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import EmptyState from "./EmptyState";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { appreciateGift, getPaidWishListItems } from "src/types/api";
import { useNavigate } from "react-router-dom";

const GiftTransfer = () => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { loading: getPaidWishListLoading, data: giftTransfer } =
    useQuery<getPaidWishListItems>(GetPaidWishListItems, {
      variables: {
        eventId: eventId,
      },
      onCompleted() {},
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const [appreciateGift, { loading: appreciateGiftLoading }] =
    useMutation<appreciateGift>(CreateAppreciation, {
      onCompleted() {
        toast.success(
          <p className="toast">
            Your appreciation note has been sent to your guest!
          </p>
        );
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const renderView = () => {
    if (getPaidWishListLoading) {
      return (
        <div className={styles["giftTransfer__loading"]}>
          <InnerLoading />
        </div>
      );
    } else if (
      !!giftTransfer &&
      !!giftTransfer.getPaidWishListItems &&
      giftTransfer.getPaidWishListItems.length
    ) {
      giftTransfer?.getPaidWishListItems.map((element) => {
        return (
          <GiftTransferTable
            key={uuidv4()}
            id={element?.id ?? 0}
            amount={element?.amount ?? 0}
            firstName={element?.user?.first_name ?? ""}
            lastName={element?.user?.last_name ?? ""}
            giftsReceived={element?.name ?? ""}
            appreciateGift={appreciateGift}
            loading={appreciateGiftLoading}
          />
        );
      });
    } else {
      return <EmptyState />;
    }
  };

  return <div className={styles["giftTransfer"]}>{renderView()}</div>;
};

export default GiftTransfer;
