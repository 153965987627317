import {
  Fragment,
  useState,
  useContext,
  SetStateAction,
  ChangeEvent,
  useEffect,
} from "react";
import Icon from "src/components/Icon";
import { useQuery, useMutation } from "@apollo/client";
import AddGuest from "./modals/AddGuest";
import styles from "./styles.module.scss";
import {
  GetEventPreference,
  InvitationList,
  NumberOfGuestsByCategory,
} from "src/graphql/queries";
import InviteListCard from "./InviteListCard";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import {
  getEventPreference,
  invitationList,
  noGuestPerCategory,
  updateEventPreference,
  updateEventPreferenceVariables,
} from "src/types/api";
import { SearchInput } from "src/components/Input";
// import * as Popover from "@radix-ui/react-popover";
import DialogModal from "src/components/Dialog";
// import UploadCSV from "./modals/UploadCSV";
import { UpdateEventPreference } from "src/graphql/mutations";
import EmptyState from "./EmptyState";
import { useNavigate } from "react-router-dom";

function InviteList() {
  // const [csvModal, setCsvModal] = useState<boolean>(false);
  const [manualModal, setManualModal] = useState<boolean>(false);
  const [, setTotalPages] = useState(1);
  // const [startPage, setStartPage] = useState(1);
  const [pageSize] = useState(100);
  const [page] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { data: eventPreference } = useQuery<getEventPreference>(
    GetEventPreference,
    {
      variables: {
        eventId: eventId,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const [smartSend, setSmartSend] = useState(
    eventPreference?.event?.event_preference?.smart_send
      ? "smart send"
      : "don't send"
  );

  const [updateEventPreference] = useMutation<
    updateEventPreference,
    updateEventPreferenceVariables
  >(UpdateEventPreference, {
    onCompleted() {
      if (smartSend === "smart send") {
        toast.success(
          <p className="toast">Invitations would be sent to all your guests.</p>
        );
      }
      if (smartSend === "don't send") {
        toast(
          <p className="toast">Invitations won't be sent to new invitees.</p>,
          {
            style: {
              background: "#fff8e6",
              border: "0.5px dashed #ffb800",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 8px",
              fontFamily: "Nunito Sans",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "19px",
              textAlign: "center",
              color: "#996e00",
              borderRadius: "0px",
            },
          }
        );
      }
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const { data: inviteList, loading: invitationListLoading } =
    useQuery<invitationList>(InvitationList, {
      variables: {
        eventId: eventId,
        page: page,
        pageSize: pageSize,
      },
      fetchPolicy: "network-only",
      onCompleted(response) {
        if (!!response?.invitationList && response?.invitationList.length) {
          setTotalPages(Math.floor(response?.invitationList.length / pageSize));
        }
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const results = !!inviteList
    ? inviteList?.invitationList?.filter(
        (guest) =>
          guest.first_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          guest.last_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
      )
    : [];

  useEffect(() => {
    !!inviteList &&
      inviteList?.invitationList.length > 0 &&
      eventId &&
      smartSend !== "" &&
      updateEventPreference({
        variables: {
          input: {
            event_id: eventId,
            smart_send: smartSend === "smart send" ? true : false,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartSend]);

  const { data: categories, loading: categoryListLoading } =
    useQuery<noGuestPerCategory>(NumberOfGuestsByCategory, {
      variables: {
        eventId: eventId,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  return (
    <div
      className={`${
        !!inviteList && inviteList?.invitationList.length > 0
          ? styles["invite-list"]
          : styles["invite-list-empty"]
      }`}
    >
      {invitationListLoading || categoryListLoading ? (
        <div className={styles["invite-list__loading"]}>
          <InnerLoading />
        </div>
      ) : !!inviteList && inviteList?.invitationList.length > 0 ? (
        <>
          <header className={styles["invite-list__header"]}>
            <div className={styles["invite-list__header--search-container"]}>
              <div className={styles["invite-list__header--search"]}>
                <div className={styles["search-input"]}>
                  <SearchInput
                    icon={<Icon iconName="search" />}
                    name="Search Guests"
                    placeholder="Search Guests"
                    value={searchValue}
                    onChange={(e: {
                      target: { value: SetStateAction<string> };
                    }) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>
              <section className={styles["invite-list__header--btn"]}>
                <DialogModal
                  trigger={
                    <div
                      className={
                        styles["invite-list__dropdown-container-button"]
                      }
                    >
                      <div>Add guest</div>
                    </div>
                  }
                  open={manualModal}
                  onOpenChange={setManualModal}
                >
                  <AddGuest
                    setOpen={setManualModal}
                    categories={categories}
                    page={page}
                    pageSize={pageSize}
                  />
                </DialogModal>
              </section>
            </div>

            <div className={styles["invite-list__header--btn__send"]}>
              <Icon iconName="caretDouble" />
              <select
                value={smartSend}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setSmartSend(e.target.value);
                }}
              >
                <option value="don't send">Don't send</option>
                <option value="smart send">Smart send</option>
              </select>
            </div>
          </header>

          <main className={styles["invite-list__main"]}>
            <div className={styles["invite-list__table"]}>
              <div className={styles["invite-list__table-header"]}>
                <h4>NAME</h4>
                <h4>EMAIL</h4>
                <h4>PHONE NUMBER</h4>
                <h4>CATEGORY</h4>
              </div>

              <div className={styles["invite-list__table-list"]}>
                {results?.map((list) => (
                  <InviteListCard
                    key={uuidv4()}
                    invitee={list}
                    page={page}
                    pageSize={pageSize}
                    categories={categories}
                  />
                ))}
              </div>
            </div>

            {/*
						<Pagination
              pageSize={pageSize}
              startPage={startPage}
              setPageSize={setPageSize}
              setStartPage={setStartPage}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
							*/}
          </main>
        </>
      ) : (
        <EmptyState categories={categories} page={page} pageSize={pageSize} />
      )}
    </div>
  );
}

export default InviteList;
